const getUserRegister = async (
  lastName,
  firstName,
  email,
  password,
  phone,
  dob
) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    lastName: lastName,
    firstName: firstName,
    email: email,
    password: password,
    phone: phone,
    dob: dob,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/user/register`,
    requestOptions
  );
};

const getUserEdit = async (
  id,
  lastName,
  firstName,
  email,
  password,
  phone,
  dob
) => {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    id: id,
    lastName: lastName,
    firstName: firstName,
    email: email,
    password: password,
    phone: phone,
    dob: dob,
  });

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/user`, requestOptions);
};

const getUserLogin = async (email, password) => {
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    email: email,
    password: password,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/user/login`,
    requestOptions
  );
};

const getUserAuth = async (token) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/user/auth`,
    requestOptions
  );
};

const getUserForgotPass = async (email) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    email: email,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/user/forgotPass`,
    requestOptions
  );
};

const getUserResetPass = async (token, password) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    resetToken: token,
    password: password,
  });
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/user/resetPass`,
    requestOptions
  );
};
export const userServices = {
  getUserRegister,
  getUserEdit,
  getUserLogin,
  getUserAuth,
  getUserForgotPass,
  getUserResetPass,
};
