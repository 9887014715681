import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { productServices } from "../../services/productServices";
import FilterDrawer from "./filterDrawer";
import "../../style/header.css";
import { useEffect, useState } from "react";

function Filter({ isOpen, setIsOpen, subCtg, ctgsub, product, price }) {
  const [count, setCount] = useState();
  function handleFltDeskBtn() {
    setIsOpen((prevState) => !prevState);
  }

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const priceSelect = searchParams.get("price");
  const cat = searchParams.get("cat");
  const sub = searchParams.get("sub");

  const subCtgPhone = ctgsub?.filter((e) => {
    if (e.pageCategoryId === parseInt(cat)) {
      return e;
    }
  });

  useEffect(() => {
    productServices
      .getAllProductCount()
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setCount(
            result.data?.filter((e) => {
              if (e.productPageCtgType === subCtg[0]?.pageCategoryId) {
                return e;
              }
            })
          );
        }
      })
      .finally(() => {});
  }, [subCtg[0]?.pageCategoryId]);

  return (
    <div className="flex justify-between lg:justify-end items-center py-4">
      <div className="block lg:hidden">
        {subCtg[0]?.pageCategoryId === 1 || parseInt(cat)
          ? "Ажлын хувцас"
          : subCtg[0]?.pageCategoryId === 2 || parseInt(cat)
          ? "Газ, Дугуй"
          : subCtg[0]?.pageCategoryId === 3 || parseInt(cat)
          ? "Танилцуулага, Мэдээ"
          : subCtg[0]?.pageCategoryId === 4 || parseInt(cat)
          ? "Нэхэмжлэл, Үнийн санал"
          : subCtg[0]?.pageCategoryId === 5 || parseInt(cat)
          ? "Холбоо барих"
          : ""}
      </div>
      <div className="flex justify-between items-center text-[14px]">
        <div>{count?.length > 0 ? <> {count?.length} Бүтээгдэхүүн</> : ""}</div>
        {/********************************** DESKTOP *******************************/}
        <button
          className="hidden md:block flex justify-between items-center ml-10"
          onClick={handleFltDeskBtn}
        >
          <div className="flex justify-between items-center text-[14px]">
            <HiOutlineAdjustmentsHorizontal />
            <span className="ml-2">
              {isOpen !== true ? "Филтер нээх" : "Филтер хаах"}
            </span>
          </div>
        </button>
        {/********************************** MOBILE *******************************/}
        {location.pathname.slice(1, 8) === "product" ||
        location.pathname.slice(1, 6) === "info/" ? (
          ""
        ) : (
          <div className="block md:hidden flex justify-between items-center ml-10">
            <FilterDrawer
              subCtg={cat ? subCtgPhone : subCtg}
              price={price}
              priceSelect={priceSelect}
              sub={sub}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Filter;
