import React, { createContext, useContext, useEffect, useState } from "react";
import { productServices } from "../services/productServices";

const initialVal = {
  gas: null,
  setGas: () => {},
};

export const ProductGasContext = createContext(initialVal);

export function useProductGas() {
  return useContext(ProductGasContext);
}

export const ProductGasProvider = (props) => {
  const [gas, setGas] = useState({
    totalPages: "",
    data: "",
  });

  useEffect(() => {
    productServices
      .getAllProductGas(1, 2)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setGas({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <ProductGasContext.Provider value={{ gas, setGas }}>
      {props.children}
    </ProductGasContext.Provider>
  );
};
export default ProductGasProvider;
