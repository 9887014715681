import { useParams, useNavigate, Outlet, useLocation } from "react-router-dom";
import { HiXMark } from "react-icons/hi2";
import { newsServices } from "../../services/newsServices";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import Card from "../card/card";

const NewsDetails = () => {
  const [data, setData] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    newsServices
      .getByIdNews(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setData(result.data);
        }
      })
      .finally(() => {});
  }, [id]);
  function handleBtnBack() {
    console.log("btn");
    navigate("/info?name=МЭДЭЭ");
  }
  return (
    <div>
      <button
        onClick={handleBtnBack}
        className="w-full text-[14px] flex items-center justify-end"
      >
        <HiXMark className="mt-[0.5px] text-[red]" />
        <span className="ml-1">Буцах</span>
      </button>
      {data ? (
        <div>
          <p className="text-[20px] mb-5">{parse(data?.newsTitle)}</p>
          <div>{parse(data?.newsBody)}</div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NewsDetails;
