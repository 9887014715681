import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useInvoiceList } from "../../contexts/invoiceListCtx";
import { Table } from "antd";
import FileOpenerModal from "./FileOpenerModal";
import moment from "moment";

export default function InvoiceList() {
  const { invoiceList, setInvoiceList } = useInvoiceList();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");

  const columns = [
    {
      title: "№",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render: (text, record,i) =>(
        <p>{i+1}</p>
      )
    },
    {
      title: "Огноо",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <p>{moment(record.createdAt).format("YYYY/DD/MM h:mm:ss")}</p>
      ),
    },
    // {
    //   title: "key",
    //   dataIndex: "key",
    //   key: "key",
    //   render: (text, record) => (
    //     <div>
    //       <p>Байгууллагын нэр : {`${record.invoiceCustName}`}</p>
    //       <p>Байгууллагын нэр : {`${record.invoiceCustRegister} `}</p>
    //       <p>Байгууллагын нэр : {`${record.invoiceDate}  `}</p>
    //       <p>Байгууллагын нэр : {`${record.invoiceFile} `}</p>
    //       <p>Байгууллагын нэр : {`${record.invoiceCustAddress} `}</p>
    //     </div>
    //   ),
    // },

    {
      title: "Дэлгэрэнгүй",
      key: "key",
      dataIndex: "key",
      render: (text, record) => (
        <button onClick={() => openModal(record)}>Харах</button>
      ),
    },
    // {
    //   title: "Татах",
    //   key: "key",
    //   dataIndex: "key",
    //   render: (text, record) => (
    //     <a
    //       href={`${process.env.REACT_APP_UPLOADS_URL}/${record?.invoiceFile}.docx`}
    //     >
    //       татах
    //     </a>
    //   ),
    // },
  ];

  // Modal
  const openModal = (record) => {
    setSelectedFile(record);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFile("");
  };

  return (
    <div>
      <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
        Хэрэглэгчийн мэдээлэл
      </h2>
      <Table dataSource={invoiceList} columns={columns} />
      <FileOpenerModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        selectedFile={selectedFile}
        invoiceList={invoiceList}
      />
   
    </div>
  );
}
