import React from "react";
import { useState } from "react";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useParams } from "react-router-dom";
import { userServices } from "../../services/userServices";
import { Notification } from "../../utils/Notification";

export default function ResetPassToken() {
  const [typeSign, setTypeSign] = useState("password");
  const [password, setPassword] = useState("");
  const [icon, setIcon] = useState(eyeOff);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const { token } = useParams();

  function handleResetPass(e) {
    e.preventDefault();
    const { password } = e.target;
    if (token) {
      userServices
        .getUserResetPass(token, password.value)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("success", result.msg);
            setModalIsOpen(false);
          } else {
            Notification.openNotification("error", result.msg);
          }
        })
        .finally(() => {});
    }
  }
  const handleToggle = () => {
    if (typeSign === "password") {
      setIcon(eye);
      setTypeSign("text");
    } else {
      setIcon(eyeOff);
      setTypeSign("password");
    }
  };

  return (
    <>
      {modalIsOpen ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="mx-5 w-[100%] md:w-[45%]">
            <div
              className="absolute inset-0 bg-black bg-opacity-50"
              onClick={closeModal}
            ></div>
            <div className="bg-white rounded-lg relative mx-2 p-8 max-w-md mx-auto z-10">
              <button
                className="absolute top-0 right-0 p-4"
                onClick={closeModal}
              >
                &times;
              </button>
              <form onSubmit={handleResetPass}>
                <h2 className="my-2 flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                  Нууц үг сэргээх
                </h2>

                <div class="flex justify-between items-center mb-2.5 ">
                  <input
                    required
                    type={typeSign}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Нууц үг"
                    className="w-full  rounded-full border border-transparent bg-[#f5f7f6] py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                  />
                  <span
                    class="flex justify-around items-center"
                    onClick={handleToggle}
                  >
                    <Icon class="absolute mr-10" icon={icon} />
                  </span>
                </div>

                <button
                  type="submit"
                  className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-base sm:text-xs"
                >
                  Нэвтрэх
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
