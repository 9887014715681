import React, { createContext, useContext, useEffect, useState } from "react";
import { invoiceServices } from "../services/invoiceServices";
import { useInvoiceLocal } from "./invoiceLocal";
import { Notification } from "../utils/Notification";

const initialVal = {
  invoiceCreateList: null,
  setInvoiceCreateList: () => {},
};

export const InvoiceCreateListContext = createContext(initialVal);

export function useInvoiceCreateListCtx() {
  return useContext(InvoiceCreateListContext);
}

export const InvoiceCreateListCtxProvider = (props) => {
  const [invoiceCreateList, setInvoiceCreateList] = useState();
  const { invoiceLocal, setInvoiceLocal } = useInvoiceLocal();
  useEffect(() => {
    if (invoiceLocal.length > 0) {
      invoiceServices
        .getInvoiceCreateList(invoiceLocal)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Notification.openNotification("success", result.msg);
            setInvoiceCreateList(result?.data);
          }
        })
        .finally(() => {});
    }
  }, [invoiceLocal]);
  return (
    <InvoiceCreateListContext.Provider
      value={{ invoiceCreateList, setInvoiceCreateList }}
    >
      {props.children}
    </InvoiceCreateListContext.Provider>
  );
};
export default InvoiceCreateListCtxProvider;
