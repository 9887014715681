import React, { createContext, useContext, useEffect, useState } from "react";
import { newsServices } from "../services/newsServices";

const initialVal = {
  about: null,
  setAbout: () => {},
};

export const AboutContext = createContext(initialVal);

export function useAbout() {
  return useContext(AboutContext);
}

export const AboutProvider = (props) => {
  const [about, setAbout] = useState();
  useEffect(() => {
    newsServices
      .getAllAbout()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setAbout(result?.data);
        }
      })
      .finally(() => {});
  }, []);

  return (
    <AboutContext.Provider value={{ about, setAbout }}>
      {props.children}
    </AboutContext.Provider>
  );
};
export default AboutProvider;
