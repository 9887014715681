import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import SubMenuFilterPhone from "./subMenuFilterPhone";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

const FilterDrawer = ({ subCtg, price, isOpen }) => {
  const [isOpenToggle, setIsOpenToggle] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const priceSelect = searchParams.get("price");
  const cat = searchParams.get("cat");
  const sub = searchParams.get("sub");
  const toggleDrawer = () => {
    setIsOpenToggle((prevState) => !prevState);
  };

  return (
    <div>
      <button onClick={toggleDrawer}>
        <HiOutlineAdjustmentsHorizontal size={22} className="ml-3 mt-1" />
      </button>
      <Drawer open={isOpenToggle} onClose={toggleDrawer} direction="right">
        <div className="p-5">
          <SubMenuFilterPhone
            subCtg={subCtg}
            isOpen={isOpen}
            price={price}
            priceSelect={priceSelect}
            cat={cat}
            sub={sub}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default FilterDrawer;
