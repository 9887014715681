const getByIdNews = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/news?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getAllNews = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/news`, requestOptions);
};

const getAllAbout = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/about`,
    requestOptions
  );
};

export const newsServices = {
  getByIdNews,
  getAllNews,
  getAllAbout,
};
