

const getAllFeedback = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/feedback`,
    requestOptions
  );
};

const getCreateFeedback = async (
  content,
  suggestion,
  ) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      content: content,
      suggestion: suggestion,
    });
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    return await fetch(
      `${process.env.REACT_APP_ADMIN_URL}/feedback`,
      requestOptions
    );
};

export const feedbackServices = {
  getAllFeedback,getCreateFeedback
};
