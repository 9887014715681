import React from "react";
import { useState } from "react";

export default function DropDown({ isOpen, onClose, children }) {
  return (
    <>
      {isOpen && (
        <div
          className="fixed mt-10 right-1 z-50 bg-[#fff] divide-y divide-gray-100 rounded-lg shadow-md w-40 dark:bg-gray-700"
          onClick={onClose}
        >
          {children}
        </div>
      )}
    </>
  );
}
