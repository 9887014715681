import React, { createContext, useContext, useEffect, useState } from "react";
import { productServices } from "../services/productServices";

const initialVal = {
  product: null,
  setProduct: () => {},
};

export const ProductContext = createContext(initialVal);

export function useProduct() {
  return useContext(ProductContext);
}

export const ProductProvider = (props) => {
  const [product, setProduct] = useState({
    totalPages: "",
    data: "",
  });

  useEffect(() => {
    productServices
      .getAllProduct(1, 1)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setProduct({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      {props.children}
    </ProductContext.Provider>
  );
};
export default ProductProvider;
