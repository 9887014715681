import { useParams, useNavigate, json } from "react-router-dom";
import { productServices } from "../../services/productServices";
import { useEffect, useState } from "react";
import { useCategory } from "../../contexts/categoryCtx";
import { useInvoiceLocal } from "../../contexts/invoiceLocal";
import { useUniinSanalLocal } from "../../contexts/uniinSanalLocal";
import { Helmet } from "react-helmet";
import Card from "../card/card";
import parse from "html-react-parser";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { ctgPage, ctgSub } = useCategory();
  const [data, setData] = useState();
  const [coverImg, setCoverImg] = useState();
  const [same, setSame] = useState();
  const { id, sub } = useParams();
  const [subcategory, setSubcategory] = useState();

  useEffect(() => {
    productServices
      .getByIdProduct(id)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setData(result.data);
          setCoverImg(result.data.productCoverImg);
        }
      })
      .finally(() => {});
    productServices
      .getSameProduct(sub)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setSame(result?.data);
        }
      })
      .finally(() => {});

    ctgSub?.ctgsub &&
      ctgSub?.ctgsub.filter(function (e) {
        if (e.id === JSON.parse(sub)) {
          return setSubcategory(e);
        }
      });
  }, [id, sub, ctgPage, ctgSub, subcategory]);

  function handleChangeImg(img) {
    setCoverImg(img);
  }

  function handleProjectEach(product) {
    navigate(`/product/${product.productId}/${product.productSubCtgType}`);
    window.location.reload();
  }
  // Nehemjlel, Uniin sanal
  const [hovered, setHovered] = useState({
    text: "",
    state: false,
  });
  const { invoiceLocal, setInvoiceLocal } = useInvoiceLocal();
  const { uniinSanalLocal, setUniinSanalLocal } = useUniinSanalLocal();
  function handleBtnInvoice(product) {
    if (!invoiceLocal.includes(product.productId)) {
      setInvoiceLocal([...invoiceLocal, product.productId]);
    }
    localStorage.setItem("invoice", JSON.stringify(invoiceLocal));
  }

  function handleBtnPriceQuote(product) {
    if (!uniinSanalLocal.includes(product.productId)) {
      setUniinSanalLocal([...uniinSanalLocal, product.productId]);
    }
    localStorage.setItem("pitch", JSON.stringify(uniinSanalLocal));
  }

  return (
    <div>
      <Helmet>
        <title>
          {`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
        </title>
        <meta
          name="title"
          content={`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          name="description"
          content={`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          name="keywords"
          content={`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content={`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:title"
          content={`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:description"
          content={`${subcategory && subcategory?.name}, ${
            data?.productName
          }, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_META}/product/${id}/${sub}`}
        />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_UPLOADS_URL}/${coverImg}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className=" md:flex mb-10">
        <div className="w-full md:w-[40vw] md:mx-20">
          <img
            src={`${process.env.REACT_APP_UPLOADS_URL}/${coverImg}`}
            className="w-full"
          />
        </div>
        <div className="flex flex-col w-full md:w-[60vw]">
          <p className="text-[20px] mb-4 font-medium border-b-2 border-gray-100 ">
            {data?.productName}
          </p>
          <p className="mb-4">
            <span className="text-[gray] text-[14px]">Үнэ :</span>
            {/* <span className="font-medium">{data?.productPrice}₮</span> */}
            <span className="font-medium">
              {data?.productPrice.toLocaleString("en-US", { style: "decimal" })}
              ₮
            </span>
          </p>
          <p className="bg-[#f1f1f1] p-2 mb-4 rounded-md">
            <span className="text-[gray] text-[14px]">Код :</span>
            <span className="font-medium">{data?.productCode}</span>
          </p>

          <p className="text-[14px] text-[gray] mb-4 border-b-2 border-gray-100">
            Үйлдвэрлэгч
          </p>
          <div className="mb-4 flex">
            {data?.productLogos.map((img, i) => {
              return (
                <img
                  key={i}
                  src={`${process.env.REACT_APP_UPLOADS_URL}/${img}`}
                  className="w-[70px] ml-1"
                />
              );
            })}
          </div>
          <p className="text-[14px] text-[gray] mb-4 border-b-2 border-gray-100">
            Өнгө болон деталь
          </p>
          <div className="mb-4 flex">
            {data?.productSameImg.map((img, i) => {
              return (
                <button key={i} onClick={() => handleChangeImg(img)}>
                  <img
                    src={`${process.env.REACT_APP_UPLOADS_URL}/${img}`}
                    className={`${`w-[70px] ml-1`}  ${
                      coverImg === img ? `border border-2 rounded-md` : ``
                    } `}
                  />
                </button>
              );
            })}
          </div>

          <div className="flex w-full mb-5">
            <button
              className={` ${
                invoiceLocal?.includes(data?.productId)
                  ? "bg-[black]"
                  : "bg-gray-300 "
              }  ml-1 p-1 text-[gray] text-[14px] rounded-full w-full relative`}
              onClick={() => handleBtnInvoice(data)}
            >
              <p className="w-[20px] text-[12px]  w-full text-center">
                Нэхэмжлэл
              </p>
            </button>

            <button
              className={` ${
                uniinSanalLocal?.includes(data?.productId)
                  ? "bg-[black]"
                  : "bg-gray-300 "
              }  ml-1 p-1 text-[gray] text-[14px] rounded-full w-full relative `}
              onClick={() => handleBtnPriceQuote(data)}
            >
              <p className="w-[20px] text-[12px] w-full text-center">
                Үнийн санал
              </p>
            </button>
          </div>

          <p className="text-[14px] text-[gray] mb-4 border-b-2 border-gray-100">
            Тайлбар
          </p>

          <div className=" text-[13px] mb-4">
            {data && typeof data.productDesc === "string"
              ? parse(data?.productDesc)
              : ""}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4">
        {same &&
          same?.map((product, i) => {
            return (
              <Card
                key={i}
                product={product}
                handleProjectEach={() => handleProjectEach(product)}
              />
            );
          })}
      </div>
    </div>
  );
};

export default ProductDetails;
