const getByIdProduct = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/product?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getAllProductCount = async () => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/product/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getSameProduct = async (sub) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product/same?sub=${sub}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getAllProduct = async (beginPage, type) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product?beginPage=${beginPage}&type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getAllProductGas = async (beginPage, type) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product?beginPage=${beginPage}&type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getAllProductInvoice = async (beginPage, type) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product?beginPage=${beginPage}&type=${type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getProductFilter = async (route) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product/filter?${route}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const getSearchPageProduct = async (beginPage, text) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/product/searchPage?beginPage=${beginPage}&text=${text}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const productServices = {
  getByIdProduct,
  getSameProduct,
  getAllProduct,
  getAllProductCount,
  getAllProductGas,
  getAllProductInvoice,
  getProductFilter,
  getSearchPageProduct,
};
