import { HiOutlineUser, HiMiniMagnifyingGlass } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import { useUniinSanalCtx } from "../../contexts/uniinSanalCtx";
import Drawer from "./drawer";
import "../../style/header.css";

function Header({ id, ctgpage }) {
  const { uniinSanal, setUniinSanal } = useUniinSanalCtx();
  const navigate = useNavigate();
  function onSearch(e) {
    e.preventDefault();
    navigate({
      pathname: "/result",
      search: `?search=${e.target.search.value}`,
    });
  }
  return (
    <section
      className="w-full z-40 sticky top-0 bg-[#fff]"
      style={{
        boxShadow:
          " rgba(0, 0, 0, 0.09) 0px 10px 20px, rgba(0, 0, 0, 0.09) 0px 3px 3px",
        // borderBottom: "1px solid gray",
      }}
    >
      <div className="w-full relative flex flex-col overflow-x-hidden overflow-y-visible">
        {/* <div className="w-full bg-[#0e72b0] bg-opacity-95 py-[2px]"></div> */}
        <div className="w-full">
          <div className="py-2 uk-container hidden lg:block">
            <div className="flex justify-between items-center">
              <div className="flex justify-between  items-center">
                <Link to="/">
                  <img src="../../logo1.png" alt="logo" className="w-[80px]" />
                </Link>
                {ctgpage?.map((e, i) => (
                  <Link
                    to={`${e.slug}`}
                    key={i}
                    className="uppercase pl-5 font-bold text-[12px]"
                    style={{
                      color: e.id === parseInt(id) ? "#000000" : "#000000",
                      fontWeight: e.id === parseInt(id) ? "800" : "500",
                    }}
                  >
                    {/* {e.name === "Нэхэмжлэл, Үнийн санал" ? (
                      <span>
                        {e.name}
                        {uniinSanal ? (
                          <span className="absolute top-4 bg-[red] px-1 ml-[-6px] rounded-full text-[#fff] text-[10px]  font-semibold ">
                            {uniinSanal?.length}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : ( */}
                     {e.name}
                    {/* )} */}
                  </Link>
                ))}
              </div>

              <form
                onSubmit={onSearch}
                className="w-[20vw] flex rounded-full border border-[1px] border-[gray] bg-[#fff] text-left text-[13px] text-secondary-500 outline-none hover:border-neutral-300 focus:border-primary-500"
              >
                <input
                  placeholder="хайх"
                  name="search"
                  className="w-full py-2 p-4 rounded-full border border-transparent bg-[#fff] outline-none border-none"
                  required
                />
                <button type="submit">
                  <HiMiniMagnifyingGlass
                    size={18}
                    className="mr-3 text-[gray] hover:text-[#000] focus:text-[#000]"
                  />
                </button>
              </form>

              <Drawer type={"desktop"} ctgpage={ctgpage} id={id} />
            </div>
          </div>
          {/**********************  MOBILE  ****************************/}
          <div className="py-2 uk-container  md:block lg:hidden">
            <div className="flex justify-between items-center">
              <div className="flex justify-between  items-center">
                <Link to="/">
                  <img src="../../logoS.png" alt="logo" className="w-[80px]" />
                </Link>
              </div>
              <form
                onSubmit={onSearch}
                className="w-[45vw] flex rounded-full border border-transparent bg-[#f5f7f6] text-left text-[13px] text-secondary-500 outline-none hover:border-neutral-300 focus:border-primary-500"
              >
                <input
                  placeholder="хайх"
                  name="search"
                  className="w-full py-2 p-4 rounded-full border border-transparent bg-[#f5f7f6] outline-none border-none"
                  required
                />
                <button type="submit">
                  <HiMiniMagnifyingGlass
                    size={18}
                    className="mr-3 text-[gray] hover:text-[#000] focus:text-[#000]"
                  />
                </button>
              </form>
              <Drawer type={"mobile"} ctgpage={ctgpage} id={id} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Header;
