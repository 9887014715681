import SubMenuFilter from "../filter/subMenuFilter";
import { useLocation, useNavigate } from "react-router-dom";
import { productServices } from "../../services/productServices";
import { useCategory } from "../../contexts/categoryCtx";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Card from "../card/card";

const ProductFilter = ({ ctgsub, isOpen, price }) => {
  const navigate = useNavigate();
  const { ctgPage, ctgSub } = useCategory();
  const [subcategory, setSubcategory] = useState();
  const [productFilter, setProductFilter] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const priceSelect = searchParams.get("price");
  const cat = searchParams.get("cat");
  const sub = searchParams.get("sub");

  const subCtg = ctgsub?.filter((e) => {
    if (e.pageCategoryId === parseInt(cat)) {
      return e;
    }
  });

  useEffect(() => {
    productServices
      .getProductFilter(`cat=${cat}&sub=${sub}&price=${priceSelect}`)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setProductFilter(result.data);
        }
      })
      .finally(() => {});
    ctgSub?.ctgsub &&
      ctgSub?.ctgsub.filter(function (e) {
        if (e.id === JSON.parse(sub)) {
          return setSubcategory(e);
        }
      });
  }, [cat, sub, priceSelect, subcategory]);

  function handleProjectEach(product) {
    navigate(`/product/${product.productId}/${product.productSubCtgType}`);
  }

  return (
    <div className="w-full h-full relative flex">
      <Helmet>
        <title>{`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}</title>
        <meta
          name="title"
          content={`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          name="description"
          content={`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          name="keywords"
          content={`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content={`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:title"
          content={`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:description"
          content={`${subcategory && subcategory?.name}, ${productFilter?.map(
            (e) => e.productName
          )}, Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_META}/filter?cat=${cat}&sub=${sub}`}
        />

        <meta property="og:type" content="website" />
      </Helmet>
      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter
          priceSelect={priceSelect}
          subCtg={subCtg}
          isOpen={isOpen}
          price={price}
          sub={sub}
          cat={cat}
        />
      </div>
      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {productFilter &&
            productFilter?.map((product, i) => {
              return (
                <Card
                  key={i}
                  product={product}
                  handleProjectEach={() => handleProjectEach(product)}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
