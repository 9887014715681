import React, { createContext, useContext, useEffect, useState } from "react";

const initialVal = {
  modalIsOpen: null,
  setModalIsOpen: () => {},
};

export const modalContext = createContext(initialVal);

export function useModal() {
  return useContext(modalContext);
}
export const ModalProvider = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  useEffect(() => {
    setModalIsOpen(!modalIsOpen);
  }, []);

  return (
    <modalContext.Provider value={{ modalIsOpen, setModalIsOpen }}>
      {props.children}
    </modalContext.Provider>
  );
};
export default ModalProvider;
