import React, { createContext, useContext, useEffect, useState } from "react";
import { newsServices } from "../services/newsServices";
import moment from "moment";

const initialVal = {
  news: null,
  setNews: () => {},
};

export const NewsContext = createContext(initialVal);

export function useNews() {
  return useContext(NewsContext);
}

export const NewsProvider = (props) => {
  const [news, setNews] = useState();
  useEffect(() => {
    newsServices
      .getAllNews()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setNews(
            result?.data.map((row, i) => ({
              newsId: row.newsId,
              newsTitle: row.newsTitle,
              newsCoverImg: row.newsCoverImg,
              newsBody: row.newsBody,
              createdBy: row.createdBy,
              createdAt: moment(row.createdAt).format("L"),
              key: i,
            }))
          );
        }
      })
      .finally(() => {});
  }, []);

  return (
    <NewsContext.Provider value={{ news, setNews }}>
      {props.children}
    </NewsContext.Provider>
  );
};
export default NewsProvider;
