import { useBanner } from "../../contexts/bannerCtx";
import "../../style/animate.css";

const Logos = () => {
  const { banner, setBanner } = useBanner();
  return (
    <div className="uk-container pt-0 ">
      {/* <div className="logos">
        <div className="logos-slide flex">
          {banner &&
            banner?.map((e, i) => {
              return (
                <img
                  key={i}
                  src={`${process.env.REACT_APP_UPLOADS_URL}/${e.bannerImg}`}
                  className="p-3"
                />
              );
            })}
        </div>
      </div> */}
      <div className="logos">
        <div className="flex logos-slide">
          {banner &&
            banner?.map((e, i) => {
              return (
                <img
                  key={i}
                  src={`${process.env.REACT_APP_UPLOADS_URL}/${e.bannerImg}`}
                  className="p-3 "
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Logos;
