import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { productServices } from "../../services/productServices";
import SubMenuFilter from "../filter/subMenuFilter";
import Pagination from "../pagination/pagination";
import { Helmet } from "react-helmet";
import Card from "../card/card";

export default function ProductSearch({ ctgsub, isOpen, price }) {
  const [data, setData] = useState({
    totalPages: "",
    data: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("search");

  const subCtg = ctgsub?.filter((e) => {
    if (e.pageCategoryId === 1 || e.pageCategoryId === 2) {
      return e;
    }
  });

  useEffect(() => {
    productServices
      .getSearchPageProduct(1, search)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setData({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      });
  }, [search]);

  function handleProjectEach(product) {
    navigate(`/product/${product.productId}/${product.productSubCtgType}`);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    productServices
      .getSearchPageProduct(newPage, search)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setData({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  };

  return (
    <div className="w-full h-full relative flex">
      <Helmet>
        <title>{`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}</title>
        <meta
          name="title"
          content={`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          name="description"
          content={`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          name="keywords"
          content={`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content={`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:title"
          content={`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:description"
          content={`${
            data.data && data.data?.map((e) => e.productName)
          } Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel`}
          data-rh="true"
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_META}/result?search=${search}`}
        />

        <meta property="og:type" content="website" />
      </Helmet>

      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter subCtg={subCtg} isOpen={isOpen} price={price} />
      </div>
      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {data.data &&
            data.data?.map((product, i) => {
              return (
                <Card
                  key={i}
                  product={product}
                  handleProjectEach={() => handleProjectEach(product)}
                />
              );
            })}
        </div>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={data?.totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
