const getByIdInvoice = async (id) => {
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/invoice?userId=${id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const getAllInvoice = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/invoice`,
    requestOptions
  );
};

const getInvoiceCreate = async (
  invoiceCustName,
  invoiceCustRegister,
  invoiceCustAddress,
  invoiceDate,
  phone,
  invoiceList,
  createdBy,
  token
) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  var raw = JSON.stringify({
    invoiceCustName: invoiceCustName,
    invoiceCustRegister: invoiceCustRegister,
    invoiceCustAddress: invoiceCustAddress,
    invoiceDate: invoiceDate,
    phone: phone,
    invoiceList: invoiceList,
    createdBy: createdBy,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/invoice`,
    requestOptions
  );
};

const getInvoiceCreateList = async (invoice) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    invoice: invoice,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/invoice/list`,
    requestOptions
  );
};

export const invoiceServices = {
  getByIdInvoice,
  getAllInvoice,
  getInvoiceCreate,
  getInvoiceCreateList,
};
