import React from "react";
import { Header, Logo, Footer, Filter, Feedback } from "../components";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import "../style/header.css";

function Layout(props) {
  return (
    <div>
      <Helmet>
        <title>
          Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin
          huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн
          санал, ajliin huwtsas, gaz, nehemjlel
        </title>
        <meta
          name="title"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          name="description"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          name="keywords"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:title"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:description"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta property="og:url" content={`https://hab.mn`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className=" w-full flex flex-col justify-between h-[100vh]">
        <div>
          <Header id={props.subPage[0]?.id} ctgpage={props.ctgpage} />
          <div className="uk-container pt-0 ">
            <Filter
              setIsOpen={props.setIsOpen}
              isOpen={props.isOpen}
              subCtg={props.subCtg}
              price={props.price}
              product={props.product}
              ctgsub={props.ctgsub}
            />
            <Outlet />
          </div>
        </div>
        <div>
          <Logo />
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Layout;
