const getByIdPrice = async (id) => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/price?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const getAllPrice = async (type) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/price`,
    requestOptions
  );
};

export const priceServices = {
  getByIdPrice,
  getAllPrice,
};
