import React, { createContext, useContext, useEffect, useState } from "react";
import { productServices } from "../services/productServices";

const initialVal = {
  invoice: null,
  setInvoice: () => {},
};

export const InvoiceContext = createContext(initialVal);

export function useInvoice() {
  return useContext(InvoiceContext);
}

export const InvoiceProvider = (props) => {
  const [invoice, setInvoice] = useState({
    totalPages: "",
    data: "",
  });

  useEffect(() => {
    productServices
      .getAllProductInvoice(1, "all")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setInvoice({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <InvoiceContext.Provider value={{ invoice, setInvoice }}>
      {props.children}
    </InvoiceContext.Provider>
  );
};
export default InvoiceProvider;
