import { notification } from "antd";

const openNotification = (type, msg) => {
  notification[type]({
    message: msg,
    duration: 2,
  });
};

export const Notification = {
  openNotification,
};
