import { HiOutlineBars3BottomLeft, HiOutlineUser } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { userServices } from "../../services/userServices";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useToken } from "../../contexts/tokenCtx";
import { useUser } from "../../contexts/userCtx";
import { useModal } from "../../contexts/modalCtx";
import Cookies from "universal-cookie";
import Modal from "../modal/LoginModal";
import Drawer from "react-modern-drawer";
import DropDown from "../modal/DropDown";
import { Notification } from "../../utils/Notification";
import "react-modern-drawer/dist/index.css";

const DrawerM = ({ type, ctgpage, id }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const { token, setToken } = useToken();

  const { user, setUser } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDrop, setIsOpenDrop] = useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const toggleDropdown = () => {
    setIsOpenDrop((prevState) => !prevState);
  };

  // Password icon
  const [password, setPassword] = useState("");
  const [typeSign, setTypeSign] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  // Modal Login
  const { modalIsOpen, setModalIsOpen } = useModal();
  const [stateSign, setStateSign] = useState("Login");

  const openModal = () => {
    setModalIsOpen(true);
    localStorage.setItem("login", setModalIsOpen);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    navigate("/ajliin-huwtsas");
  };

  const handleSign = (e, state) => {
    e.preventDefault();
    setStateSign(state);
  };
  const handleToggle = () => {
    if (typeSign === "password") {
      setIcon(eye);
      setTypeSign("text");
    } else {
      setIcon(eyeOff);
      setTypeSign("password");
    }
  };

  function handleFormLogin(e) {
    e.preventDefault();
    const { email, password } = e.target;
    userServices
      .getUserLogin(email.value, password.value)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("success", result.msg);
          setToken(result.token);
          setModalIsOpen(false);
        } else {
          Notification.openNotification("error", result.msg);
        }
      })
      .finally(() => {});
  }

  function handleFormRegister(e) {
    e.preventDefault();
    const { lastName, firstName, email, password, phone, dob } = e.target;
    userServices
      .getUserRegister(
        lastName.value,
        firstName.value,
        email.value,
        password.value,
        phone.value,
        dob.value
      )
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("success", result.msg);
          setStateSign("Login");
        } else {
          Notification.openNotification("error", result.msg);
        }
      })
      .finally(() => {});
  }

  function handleFormReset(e) {
    e.preventDefault();
    const { email } = e.target;
    userServices
      .getUserForgotPass(email.value)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Notification.openNotification("success", result.msg);
        } else {
          Notification.openNotification("error", result.msg);
        }
      })
      .finally(() => {});
  }
  function handleFormLogOut() {
    Notification.openNotification("success", "Хэрэглэгч гарсан");
    cookies.remove("token");
    setUser();
  }

  return (
    <div className="flex">
      {type === "desktop" ? (
        !user ? (
          <button
            onClick={openModal}
            className="text-[13px] flex justify-between items-center bg-[#000] p-2 text-[#fff] rounded-md"
          >
            <span className="ml-1 uppercase font-medium">Нэвтрэх</span>
          </button>
        ) : (
          <div className="flex justify-center items-center">
            <button onClick={toggleDropdown} className="ml-1">
              {user?.firstName}
            </button>
          </div>
        )
      ) : (
        <div className="flex justify-between items-center">
          {!user ? (
            <button onClick={openModal}>
              <HiOutlineUser size={22} />
            </button>
          ) : (
            <button onClick={toggleDropdown}>
              <p className="uppercase bg-[#000] text-[#fff] px-3 py-1 rounded-full">
                {user?.firstName.split("")[0]}
              </p>
            </button>
          )}
          <button onClick={toggleDrawer}>
            <HiOutlineBars3BottomLeft size={22} className="ml-3 mt-1" />
          </button>
        </div>
      )}
      {/* Drawer right */}
      <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
        <div className="p-5">
          {ctgpage?.map((e, i) => (
            <Link
              to={`/${e.slug}`}
              key={i}
              className="font-[500] text-[13px]"
              style={{
                color: e.id === parseInt(id) ? "#000000" : "#000000",
                fontWeight: e.id === parseInt(id) ? "800" : "500",
              }}
            >
              <p className="uppercase p-[3px]"> {e.name}</p>
            </Link>
          ))}
        </div>
      </Drawer>
      {/* Modal center */}
      <Modal isOpen={modalIsOpen} closeModal={closeModal}>
        <div className="text-center text-[#000] ">
          {stateSign === "Login" ? (
            <form onSubmit={handleFormLogin}>
              <h2 className="my-2 flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                НЭВТРЭХ
              </h2>
              <input
                required
                type="email"
                placeholder="Имэйл хаяг"
                name="email"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              <div class="flex justify-between items-center mb-2.5 ">
                <input
                  required
                  type={typeSign}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Нууц үг"
                  className="w-full  rounded-full border border-transparent bg-[#f5f7f6] py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                />
                <span
                  class="flex justify-around items-center"
                  onClick={handleToggle}
                >
                  <Icon class="absolute mr-10" icon={icon} />
                </span>
              </div>
             
              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-base sm:text-xs"
              >
                Нэвтрэх
              </button>
              <button
                className="mt-3 text-right"
                onClick={(e) => handleSign(e, "ResetPass")}
              >
                <span className="text-[12px] border-b border-[#000]">
                  Нууц үг шинэчлэх
                </span>
              </button> <br/>
              <button
                className="mt-3"
                onClick={(e) => handleSign(e, "Register")}
              >
                <span className="text-[12px] mr-2">Та бүртгэлтэй юу</span>
                <span className="border-b border-[#000] text-[14px]">
                  Бүртгүүлэх
                </span>
              </button>
            </form>
          ) : stateSign === "Register" ? (
            <form onSubmit={handleFormRegister}>
              <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                БҮРТГҮҮЛЭХ
              </h2>
              <input
                required
                type="text"
                name="lastName"
                placeholder="Овог"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <input
                required
                type="text"
                name="firstName"
                placeholder="Нэр"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <input
                required
                type="email"
                name="email"
                placeholder="Имэйл хаяг"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <div class="flex justify-between items-center mb-2.5 ">
                <input
                  required
                  type={typeSign}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Нууц үг"
                  className="w-full  rounded-full border border-transparent bg-[#f5f7f6] py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
                />
                <span
                  class="flex justify-around items-center"
                  onClick={handleToggle}
                >
                  <Icon class="absolute mr-10" icon={icon} />
                </span>
              </div>

              <input
                required
                type="text"
                name="phone"
                placeholder="Утасны дугаар"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />
              <input
                required
                type="date"
                name="dob"
                placeholder="Төрсөн он сар өдөр"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-base sm:text-xs"
              >
                Бүртгүүлэх
              </button>
              <button className="mt-5 " onClick={(e) => handleSign(e, "Login")}>
                <span className="text-[12px] mr-2">Та бүртгэлтэй юу</span>
                <span className="border-b border-[#000] text-[14px]">
                  Нэвтрэх
                </span>
              </button>
            </form>
          ) : (
            <form onSubmit={handleFormReset}>
              <h2 className="my-2 flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
                Нууц үг сэргээх
              </h2>
              <p className="text-base sm:text-xs mb-5">
                Та бүртгэлтэй И-Mейл хаягаа оруулж нууц үг сэргээх холбоос авна
                уу.
              </p>
              <input
                required
                type="email"
                placeholder="Имэйл хаяг"
                name="email"
                className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
              />

              <button
                type="submit"
                className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-base sm:text-xs"
              >
                Сэргээх
              </button>
              <button className="mt-5" onClick={(e) => handleSign(e, "Login")}>
                <span className="text-[12px] mr-2"></span>
                <span className="border-b border-[#000] text-[14px]">
                  Нэвтрэх
                </span>
              </button>
            </form>
          )}
        </div>
      </Modal>

      {user ? (
        <DropDown isOpen={isOpenDrop} onClose={toggleDropdown}>
          <div className="text-left relative text-[15px] h-40 w-60 flex flex-col items-start px-5 justify-center">
            <Link to={`/user/${user?.id}`} className="mb-2">
              Миний булан
            </Link>
            <Link to={`/list/${user?.id}`} className="mb-2">
              Татсан нэхэмжлэл
            </Link>
            <button onClick={handleFormLogOut}>Гарах</button>
          </div>
        </DropDown>
      ) : (
        ""
      )}
    </div>
  );
};

export default DrawerM;
