import SubMenuFilter from "../filter/subMenuFilter";
import Card from "../card/card";
import { useNavigate } from "react-router-dom";
import Pagination from "../pagination/pagination";
import { productServices } from "../../services/productServices";
import { useState } from "react";
import { Helmet } from "react-helmet";

const Gas = ({ subCtg, isOpen, price, gas, setGas }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  function handleProjectEach(product) {
    navigate(`/product/${product.productId}/${product.productSubCtgType}`);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    productServices
      .getAllProductGas(1, 2)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setGas({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  };

  return (
    <div className="w-full h-full relative flex">
      <Helmet>
        <title>
          АЖЛЫН ХУВЦАС, АЖЛЫН ХУВЦАС/AJLIIN HUWTSAS,AJLIIN HUWTSAS, Ажлын
          хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ,
          мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab,
          hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг
        </title>
        <meta
          name="title"
          content="АЖЛЫН ХУВЦАС, АЖЛЫН ХУВЦАС/AJLIIN HUWTSAS,AJLIIN HUWTSAS, Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг"
        />
        <meta
          name="keywords"
          content="АЖЛЫН ХУВЦАС, АЖЛЫН ХУВЦАС/AJLIIN HUWTSAS,AJLIIN HUWTSAS, Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг"
        />
      </Helmet>
      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter subCtg={subCtg} isOpen={isOpen} price={price} />
      </div>
      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {gas.data &&
            gas.data?.map((product, i) => {
              return (
                <Card
                  key={i}
                  product={product}
                  handleProjectEach={() => handleProjectEach(product)}
                />
              );
            })}
        </div>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={gas?.totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Gas;
