import React, { useEffect, useState } from "react";
import { Table } from "antd";
import "../../style/table.css"

const FileOpenerModal = ({ isOpen, closeModal, selectedFile,invoiceList }) => {

 const [totalSum, setTotalSum] = useState()
useEffect(()=>{
  let totalSumFil = selectedFile &&  selectedFile?.invoiceList.reduce((acc, item) => {
    return acc + item.productPiece * item.productPrice;
  }, 0);

  setTotalSum(totalSumFil)
},[selectedFile])
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="mx-5">
            <div
              className="absolute inset-0 bg-black bg-opacity-50"
              onClick={closeModal}
            ></div>

            <div className="bg-white rounded-lg relative mx-2 p-8 w-[400px] md:w-[700px] lg:w-[1000px] mx-auto z-10">
             
             <p>Нэхэмжлэлийн дугаар : {
              selectedFile?.invoiceNumber
             }</p>
              <table className="mt-5" id="customers" >
  <tr>
    <th>№</th>
    <th>Барааны код</th>
    <th>Гүйлгээний утга</th>
    <th>Тоо хэмжээ</th>
    <th>Нэгжийн үнэ</th>
    <th>Нийт үнэ</th>
  </tr>
  {
    selectedFile?.invoiceList?.map((e,i)=>{
      return <tr>
      <td>{i+1}</td>
      <td>{e.productCode}</td>
      <td>{e.productName}</td>
      <td>{e.productPiece}</td>
      <td>{e.productPrice.toLocaleString("en-US", {
                            style: "decimal",
                          })}</td>
      <td>{(
                            e.productPrice * e.productPiece
                          ).toLocaleString("en-US", { style: "decimal" })}</td>
    </tr>
    })
  }
  <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Дүн</td>
      <td>{
        (totalSum && totalSum / 1.1).toLocaleString("en-US", {
          style: "decimal",
        })}</td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>НӨАТ</td>
      <td>{
        (((totalSum && totalSum / 1.1) * 10) / 100).toLocaleString(
          "en-US",
          {
            style: "decimal",
          }
        )}</td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Нийт дүн</td>
      <td>{
      totalSum &&  totalSum.toLocaleString("en-US", {
          style: "decimal",
        })}</td>
    </tr>

</table>
              <button className="absolute top-3 right-6 " onClick={closeModal}>
                x
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FileOpenerModal;
