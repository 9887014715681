import {
  CiClock2,
  CiMail,
  CiLocationOn,
  CiFacebook,
  CiInstagram,
  CiLinkedin,
  CiGlobe,
} from "react-icons/ci";
import { Helmet } from "react-helmet";

const Footer = () => {
  return (
    <div
      className="w-full"
      style={{
        boxShadow:
          " rgba(0, 0, 0, 0.09) 0px -6px 6px,  0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -1px 3px",
      }}
    >
      <Helmet>
        <title>
          Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын
          хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz,
          nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр
          цэцэг, ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд Түмэн наст хотхоны
          36Б байр Ажлын хувцасны дэлгүүр,10-р хороолол MT колонктой уулзварын
          баруун урд зам дагуу Ажлын хувцасны дэлгүүр
        </title>
        <meta
          name="title"
          content="Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг, ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд Түмэн наст хотхоны 36Б байр Ажлын хувцасны дэлгүүр,10-р хороолол MT колонктой уулзварын баруун урд зам дагуу Ажлын хувцасны дэлгүүр"
          data-rh="true"
        />
        <meta
          name="description"
          content="Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг, ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд Түмэн наст хотхоны 36Б байр Ажлын хувцасны дэлгүүр,10-р хороолол MT колонктой уулзварын баруун урд зам дагуу Ажлын хувцасны дэлгүүр"
          data-rh="true"
        />
        <meta
          name="keywords"
          content="Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг, ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд Түмэн наст хотхоны 36Б байр Ажлын хувцасны дэлгүүр,10-р хороолол MT колонктой уулзварын баруун урд зам дагуу Ажлын хувцасны дэлгүүр"
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content="Ажлын хувцас, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, ажлын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel, hab, hab.mn,www.hab.mn, сутай ихэр цэцэг ххк, сутай ихэр цэцэг, ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд Түмэн наст хотхоны 36Б байр Ажлын хувцасны дэлгүүр,10-р хороолол MT колонктой уулзварын баруун урд зам дагуу Ажлын хувцасны дэлгүүр"
          data-rh="true"
        />
        <meta
          property="og:title"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:description"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_META}/ajliin-huwtsas`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className=" text-[14px]">
        <div className="container mx-auto ">
          <div className="p-5 grid grid-cols-1 md:grid-cols-3">
            <div className="mb-3">
              <p className="text-[1.2em] mb-3 font-medium flex items-center">
                <span className="p-1 border border-[#000] rounded">
                  <CiLocationOn size={23} />
                </span>
                <span className="ml-2"> Төв салбар</span>
              </p>

              <p>
                Хаяг : ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд "Түмэн наст"
                <br />
                хотхоны 36Б байр Ажлын хувцасны дэлгүүр
              </p>
              <p>Утас : 7602-7615, 8822-7615 </p>
              <p> Үйл ажиллагаа хариуцсан менежер: 9129-7615 </p>
            </div>

            <div className="mb-3">
              <p className="text-[1.2em]  mb-3 font-medium flex items-center">
                <span className="p-1 border border-[#000] rounded">
                  <CiLocationOn size={23} />
                </span>
                <span className="ml-2">Салбар 2</span>
              </p>
              <p>
                Хаяг : 10-р хороолол MT колонктой уулзварын баруун урд <br />
                зам дагуу Ажлын хувцасны дэлгүүр
              </p>

              <p>Утас : 7711-7615, 8833-7615</p>
            </div>

            <div className="text-left md:text-left">
              {/* <p className="text-[1.3em]">Холбоо барих</p> */}
              <div className="flex items-center">
                <p className="p-1 border border-[#000] rounded">
                  <CiClock2 size={23} />
                </p>
                <div className="ml-3">
                  <p>Даваа - Баасан 09:00-19:00 </p>
                  <p>Бямба: 10:00-16:00 </p>
                  <p> Ням Амарна</p>
                </div>
              </div>
              <div className="flex items-center mt-3">
                <p className="p-1 border border-[#000] rounded">
                  <CiMail size={23} />
                </p>
                <div className="ml-3">
                  <a href="mailto:76027615.hab@gmail.com">
                    76027615.hab@gmail.com
                  </a>
                </div>
              </div>
              <div className="flex items-center mt-3">
                <p className="p-1 border border-[#000] rounded">
                  <CiGlobe size={23} />
                </p>
                <div className="ml-3">
                  <p>info@hab.mn</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-5 flex flex-col md:flex-row justify-between border-[#000] border-t-[1px]">
            <div className="flex items-start mt-3">
              <p className="p-1 border border-[#000] rounded">
                <a target="_blank" href="https://www.facebook.com/www.hab.mn">
                  <CiFacebook size={23} />
                </a>
              </p>
              <p className="p-1 mx-3 border border-[#000] rounded">
                <a target="_blank" href="http://www.hab.mn/">
                  <CiInstagram size={23} />
                </a>
              </p>
              <p className="p-1 border border-[#000] rounded">
                <a target="_blank" href="http://www.hab.mn/">
                  <CiLinkedin size={23} />
                </a>
              </p>
            </div>
            <p className="py-5 text-[12px] text-left md:text-right">
              Copyright © Зохиогчийн эрхийн хуулиар хамгаалагдана 2018
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
