import SubMenuFilter from "../filter/subMenuFilter";
import { useLocation } from "react-router-dom";
import { productServices } from "../../services/productServices";
import { Notification } from "../../utils/Notification";
import Pagination from "../pagination/pagination";
import Nehemjlel from "./nehemjlel";
import Counter from "./counter";
import { FaSquareCheck } from "react-icons/fa6";
import { HiXMark, HiArrowLongRight } from "react-icons/hi2";
import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  ImageRun,
  AlignmentType,
  TextRun,
  Footer,
  Header,
  HeadingLevel,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useUniinSanalCtx } from "../../contexts/uniinSanalCtx";
import moment from "moment";
import Pdf from "./pdf";

const UniinSanal = ({ subCtg, isOpen, product, setInvoice }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const [selectedRows, setSelectedRows] = useState([]);
  const [customer, setCustomer] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { uniinSanal, setUniinSanal } = useUniinSanalCtx();
  // onChange value
  function onChangeBtn(e) {
    setCustomer(e.target.value.toUpperCase());
  }
  // Total number of pages
  const handleRowSelect = (rowKey) => {
    rowKey["productPiece"] = 1;
    if (selectedRows.includes(rowKey)) {
      setSelectedRows(
        selectedRows.filter((key) => key.productId !== rowKey.productId)
      );
    } else {
      setSelectedRows([...selectedRows, rowKey]);
    }
  };

  async function urlToBlob(url) {
    return (await fetch(url)).blob();
  }

  // uniinSanal DELETE
  const handleDltIn = (item, i) => {
    setUniinSanal(
      uniinSanal.filter(function (item, index) {
        return index !== i;
      })
    );
  };

  async function handleSubmit() {
    if (customer) {
      if (selectedRows.length !== 0) {
        let totalPiece = selectedRows.reduce((acc, item) => {
          return acc + item.productPiece;
        }, 0);
        let totalPrice = selectedRows.reduce((acc, item) => {
          return acc + item.productPrice;
        }, 0);
        let totalSum = selectedRows.reduce((acc, item) => {
          return acc + item.productPiece * item.productPrice;
        }, 0);
        const doc = new Document({
          sections: [
            {
              headers: {
                default: new Header({
                  children: [
                    new Table({
                      width: {
                        size: 100,
                        type: WidthType.PERCENTAGE,
                      },
                      borders: {
                        top: { style: "none" },
                        bottom: { style: "none" },
                        left: { style: "none" },
                        right: { style: "none" },
                        insideHorizontal: { style: "none" },
                        insideVertical: { style: "none" },
                      },
                      rows: [
                        new TableRow({
                          children: [
                            new TableCell({
                              width: {
                                size: 50,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun({
                                      text: `ҮНИЙН САНАЛ ХҮРГҮҮЛЭХ`,
                                      font: "Calibri Light",
                                    }),
                                  ],
                                  alignment: AlignmentType.LEFT,
                                }),
                              ],
                            }),
                            new TableCell({
                              width: {
                                size: 50,
                                type: WidthType.PERCENTAGE,
                              },
                              children: [
                                new Paragraph({
                                  children: [
                                    new TextRun(moment(new Date()).format("L")),
                                  ],
                                  alignment: AlignmentType.RIGHT,
                                }),
                              ],
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
              },
              footers: {
                default: new Footer({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: `СУТАЙ ИХЭР ЦЭЦЭГ ХХК`,
                          font: "Calibri Light",
                        }),
                      ],
                      alignment: AlignmentType.RIGHT,
                    }),
                  ],
                }),
              },
              children: [
                new Table({
                  width: {
                    size: "100%",
                  },
                  borders: {
                    top: { style: "none" },
                    bottom: { style: "none" },
                    left: { style: "none" },
                    right: { style: "none" },
                    insideHorizontal: { style: "none" },
                    insideVertical: { style: "none" },
                  },

                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new ImageRun({
                                  data: await urlToBlob("./logoS.png"), // Replace with your image URL
                                  transformation: {
                                    width: 140,
                                    height: 85,
                                  },
                                }),
                              ],
                            }),
                          ],
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "ВЕБСАЙТ : ",
                                  font: "Calibri Light",
                                }),
                                new TextRun({
                                  text: "WWW.HAB.MN",
                                  type: "hyperlink",
                                  href: `https://www.google.com/`,
                                  font: "Calibri Light",
                                  bold: true,
                                  language: "mn-MN",
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),

                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "ИМАЙЛ ХАЯГ : ",
                                  font: "Calibri Light",
                                }),
                                new TextRun({
                                  text: "76027615.hab@gmail.com",
                                  type: "hyperlink",
                                  href: `https://www.google.com/`,
                                  font: "Calibri Light",
                                  bold: true,
                                  language: "mn-MN",
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "ХОЛБОО БАРИХ : ",
                                  font: "Calibri Light",
                                }),
                                new TextRun({
                                  text: "7602-7615, 9129-7615, 88807615",
                                  font: "Calibri Light",
                                  bold: true,
                                  language: "mn-MN",
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "САЛБАР 1 : ",
                                  font: "Calibri Light",
                                }),
                                new TextRun({
                                  text: "БЗД ХОРООЛОЛ ТҮМЭННАСТ ХОТХОН",
                                  font: "Calibri Light",
                                  bold: true,
                                  language: "mn-MN",
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),
                            new Paragraph({
                              children: [
                                new TextRun({
                                  text: "САЛБАР 2 : ",
                                  font: "Calibri Light",
                                }),
                                new TextRun({
                                  text: `БГД 10 ХОРООЛОЛ АВЗАГА БАЙР`,
                                  font: "Calibri Light",
                                  bold: true,
                                  language: "mn-MN",
                                }),
                              ],
                              alignment: AlignmentType.RIGHT,
                            }),
                          ],
                        }),
                      ],
                    }),
                  ],
                }),
                new Paragraph({
                  text: `${customer} -д`,
                  capitalize: true,
                  spacing: {
                    before: 400,
                    after: 400,
                  },

                  heading: HeadingLevel.HEADING_3,
                  bold: true,
                  language: "mn-MN",
                  alignment: AlignmentType.CENTER,
                }),
                new Table({
                  width: {
                    size: "100%",
                  },
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({ children: [new Paragraph("№")] }),
                        new TableCell({
                          children: [new Paragraph("БАРАА БҮТЭЭГДЭХҮҮН")],
                        }),
                        new TableCell({
                          children: [new Paragraph("КОД")],
                        }),
                        new TableCell({
                          children: [new Paragraph("ТОО ШИРХЭГ")],
                        }),
                        new TableCell({
                          children: [new Paragraph("НЭГЖ ҮНЭ")],
                        }),
                        new TableCell({
                          children: [new Paragraph("НИЙТ ҮНЭ")],
                        }),
                      ],
                    }),
                  ],
                }),
                new Table({
                  width: {
                    size: "100%",
                  },
                  rows: selectedRows?.map(
                    (e, i) =>
                      new TableRow({
                        children: [
                          new TableCell({
                            children: [
                              new Paragraph({
                                text: `${i + 1}`,
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            properties: {
                              shading: {
                                fill: "auto",
                                val: "clear",
                              },
                              borders: {
                                top: { val: "single", color: "auto", space: 0 },
                                bottom: {
                                  val: "single",
                                  color: "auto",
                                  space: 0,
                                },
                                left: {
                                  val: "single",
                                  color: "auto",
                                  space: 0,
                                },
                                right: {
                                  val: "single",
                                  color: "auto",
                                  space: 0,
                                },
                              },
                            },
                            verticalAlign: "center",
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                children: [
                                  new ImageRun({
                                    data: urlToBlob(
                                      `${process.env.REACT_APP_UPLOADS_URL}/${e.productCoverImg}`
                                    ),
                                    transformation: {
                                      width: 140,
                                      height: 110,
                                    },
                                  }),
                                ],
                              }),
                            ],
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                text: `${e.productCode}`,
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            verticalAlign: "center",
                            alignment: AlignmentType.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                text: `${e.productPiece.toLocaleString(
                                  "en-US",
                                  { style: "decimal" }
                                )}`,
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            verticalAlign: "center",
                            alignment: AlignmentType.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                text: `${e.productPrice.toLocaleString(
                                  "en-US",
                                  { style: "decimal" }
                                )}`,
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            verticalAlign: "center",
                            alignment: AlignmentType.CENTER,
                          }),
                          new TableCell({
                            children: [
                              new Paragraph({
                                text: `${(
                                  e.productPrice * e.productPiece
                                ).toLocaleString("en-US", {
                                  style: "decimal",
                                })}`,
                                alignment: AlignmentType.CENTER,
                              }),
                            ],
                            verticalAlign: "center",
                            alignment: AlignmentType.CENTER,
                          }),
                        ],
                      })
                  ),
                }),

                new Table({
                  width: {
                    size: "100%",
                  },
                  borders: {
                    top: { style: "none" },
                    bottom: { style: "none" },
                    left: { style: "none" },
                    right: { style: "none" },
                    insideHorizontal: { style: "none" },
                    insideVertical: { style: "none" },
                  },
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [new Paragraph(``)],
                          verticalAlign: "center",
                        }),
                        new TableCell({
                          children: [new Paragraph(``)],
                          verticalAlign: "center",
                        }),
                        new TableCell({
                          children: [new Paragraph(``)],
                          verticalAlign: "center",
                        }),
                        new TableCell({
                          children: [new Paragraph(``)],
                          verticalAlign: "center",
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `Нийт дүн`,
                              alignment: AlignmentType.LEFT,
                            }),
                          ],
                          verticalAlign: "center",
                          borders: {
                            top: {
                              style: "single",
                              color: "000000",
                              size: 8,
                            },
                            bottom: {
                              style: "single",
                              color: "000000",
                              size: 8,
                            },
                            right: {
                              style: "single",
                              color: "000000",
                              size: 8,
                            },
                            left: { style: "single", color: "000000", size: 8 },
                          },
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: `${totalSum.toLocaleString("en-US", {
                                style: "decimal",
                              })}`,
                              alignment: AlignmentType.CENTER,
                            }),
                          ],
                          borders: {
                            top: {
                              style: "single",
                              color: "000000",
                              size: 8,
                            },
                            bottom: {
                              style: "single",
                              color: "000000",
                              size: 8,
                            },
                            right: {
                              style: "single",
                              color: "000000",
                              size: 8,
                            },
                            left: { style: "single", color: "000000", size: 8 },
                          },
                          verticalAlign: "center",
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                    }),
                  ],
                }),

                new Paragraph({
                  text: `*НӨАТ багтсан дүн`,
                  capitalize: true,
                  spacing: {
                    before: 400,
                    after: 400,
                  },

                  heading: HeadingLevel.HEADING_3,
                  bold: true,
                  color: "#FF0000",
                  language: "mn-MN",
                  alignment: AlignmentType.LEFT,
                }),
              ],
            },
          ],
        });
        Packer.toBlob(doc).then((blob) => {
          saveAs(blob, "uniinsanal.docx");
          Notification.openNotification("success", "Үнийн санал татагдлаа");
          setUniinSanal([]);
          localStorage.removeItem("pitch");
        });
      } else {
        Notification.openNotification(
          "success",
          `зурагны урд талын чекбоксыг идвэхжүүлнэ уу ?`
        );
      }
    } else {
      Notification.openNotification(
        "success",
        "Байгууллагын нэрээ оруулана уу ?"
      );
    }
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    productServices
      .getAllProductInvoice(newPage, "all")
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setInvoice({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  };

  return (
    <div className="w-full h-full relative flex">
      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter subCtg={subCtg} isOpen={isOpen} />
      </div>

      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        <div className="w-full">
          {name === "nehemjlel" ||
          name === "Нэхэмжлэх" ||
          name === "НЭХЭМЖЛЭХ" ? (
            <Nehemjlel />
          ) : (
            <>
              {uniinSanal ? (
                <>
                  <div>
                    <label>Байгууллагын нэрээ оруулана уу ?</label>
                    <input
                      className="w-full border border-[gray] border-[1px] px-3 py-1 my-3  rounded-md outline-0"
                      onChange={onChangeBtn}
                    />
                  </div>
                  <div className="overflow-scroll w-[95vw] md:w-full md:overflow-auto rounded-md">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-2 py-2">
                            #
                          </th>
                          <th scope="col" className="px-2 py-2">
                            Зураг
                          </th>
                          <th scope="col" className="px-2 py-2">
                            <div className="flex items-center">Нэр</div>
                          </th>
                          <th scope="col" className="px-2 py-2">
                            <div className="flex items-center">Код</div>
                          </th>
                          <th scope="col" className="px-2 py-2">
                            <div className="flex items-center">Үнэ</div>
                          </th>
                          <th scope="col" className="px-2 py-2">
                            <div className="flex items-center">Ширхэг</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniinSanal &&
                          uniinSanal.map((item, i) => {
                            return (
                              <tr
                                key={item.productId}
                                className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                                  selectedRows.includes(item)
                                    ? "bg-gray-100"
                                    : "bg-[#000]"
                                }`}
                              >
                                <td className="px-2 py-2">
                                  <input
                                    type="checkbox"
                                    checked={selectedRows.includes(item)}
                                    onChange={() => handleRowSelect(item)}
                                  />
                                </td>
                                <th
                                  scope="row"
                                  className="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                  <img
                                    src={`${process.env.REACT_APP_UPLOADS_URL}/${item.productCoverImg}`}
                                    className="w-[50px]"
                                  />
                                </th>
                                <td className="px-2 py-2">
                                  {item.productName}
                                </td>
                                <td className="px-2 py-2">
                                  {item.productCode}
                                </td>
                                <td className="px-2 py-2">
                                  {selectedRows.includes(item)
                                    ? item.productPrice * item.productPiece
                                    : item.productPrice}
                                </td>
                                <td className="px-2 py-2 text-left">
                                  <Counter
                                    id={item.productId}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                  />
                                </td>
                                <td className="px-2 py-2">
                                  <button onClick={() => handleDltIn(item, i)}>
                                    <HiXMark />
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {/* <Pagination
                      currentPage={currentPage}
                      totalPages={product?.totalPages}
                       onPageChange={handlePageChange}
                    /> */}
                  <button
                    onClick={handleSubmit}
                    className="w-full bg-[#000000] my-10 p-1 rounded-md text-white"
                  >
                    Үнийн санал татах
                  </button>
                </>
              ) : (
                <video
                  autoPlay={true}
                  playsInline
                  controls
                  className={`w-full mb-10`}
                >
                  <source src={`./info.mp4`} type="video/mp4" />
                </video>
              )}
            </>
          )}
        </div>
      </div>
      {/* <Pdf /> */}
    </div>
  );
};

export default UniinSanal;
