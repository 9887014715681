import { useState, useRef } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
Font.register({
  family: "SourceSansPro",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPw.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkA.ttf",
      fontWeight: 600,
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    fontFamily: "SourceSansPro",
    padding: "20px",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  text1: {
    fontSize: "12px",
    textAlign: "right",
  },
  text2: {
    textAlign: "center",
    fontSize: "13px",
    marginTop: "5px",
    marginBottom: "5px",
  },
  text3: {
    textAlign: "left",
    fontSize: 12,
    // marginTop: "10px"
  },
  text4: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: 600,
    // marginTop: "10px"
  },
  text5: {
    textAlign: "center",
    fontSize: 12,
    // marginTop: "10px"
  },
  // table css
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    // marginBottom: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol1: {
    width: "35%",
    borderBottom: "1px solid #000",
    padding: 5,
  },
  tableCol2: {
    width: "65%",
    borderBottom: "1px solid #000",
    borderLeft: "1px solid  #000",
    padding: 5,
  },
  tableCol3: {
    width: "100%",
    borderBottom: "1px solid #000",
    padding: 5,
  },
  tableCol4: {
    width: "100%",
    padding: 5,
  },
  tableCell: {
    margin: 5,
    fontSize: 12,
  },
  footer: {
    marginTop: "50px",
    textAlign: "center",
  },
  // space
  space: {
    left: "20px",
  },
});
export default function Pdf() {
  const [isClient, setIsClient] = useState(true);
  return (
    <div>
      {" "}
      <div style={{ width: "100%", height: "80vh" }}>
        {isClient && (
          <PDFViewer style={{ width: "100%", height: "100%" }}>
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.table}>
                  {/* Table Header */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>
                        Чөлөө хүссэн ажилтны харьялагдах газар, нэгж
                      </Text>
                    </View>
                    <View style={styles.tableCol2}>
                      <Text style={styles.tableCell}>
                        {" "}
                        {/* {user?.structures.parent_name} */}
                      </Text>
                    </View>
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    </div>
  );
}
