import { useState, useRef } from "react";
import { HiXMark, HiArrowLongRight } from "react-icons/hi2";
import { invoiceServices } from "../../services/invoiceServices";
import { Notification } from "../../utils/Notification";
import { useUser } from "../../contexts/userCtx";
import { useToken } from "../../contexts/tokenCtx";
import { useModal } from "../../contexts/modalCtx";
import Counter from "./counter";
import Table from "./tableInvoice";
import { useInvoiceCreateListCtx } from "../../contexts/InvoiceCreateList";
import Modal from "./invoiceCreate";
import moment from "moment";

const Nehemjlel = () => {
  const [formValues, setFormValues] = useState([]);
  const { user, setUser } = useUser();
  const { token, setToken } = useToken();
  const transactionValueRef = useRef();
  const qtyRef = useRef();
  const unitPriceRef = useRef();
  const totalPriceRef = useRef();
  const invoiceDate = moment(new Date()).format("L");
  // Form SUBMIT

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (token && invoiceDate) {
      if (selectedRows.length !== 0) {
        let response = await invoiceServices.getInvoiceCreate(
          e.target.custName.value,
          e.target.custRegister.value,
          e.target.custAddress.value,
          invoiceDate,
          e.target.phone.value,
          // formValues,
          selectedRows,
          user?.id,
          token
        );
        if (response.ok) {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = "Nehemjlel.docx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(blobUrl);
          e.target.reset();
          setFormValues([]);
          Notification.openNotification(
            "success",
            "Нэхэмжлэл амжилттай татагдлаа"
          );
          setInvoiceCreateList([]);
          localStorage.removeItem("invoice");
        } else {
          console.error(
            "Error downloading document. Server returned:",
            response.status,
            response.statusText
          );
        }
      } else {
        Notification.openNotification(
          "success",
          `зурагны урд талын чекбоксыг идвэхжүүлнэ уу ?`
        );
      }
    } else {
      Notification.openNotification("error", "Та нэвтэрч орно уу ?");
    }
  };

  // modalJs
  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  // login modal open
  const { modalIsOpen, setModalIsOpen } = useModal();
  const openModal = (e) => {
    e.preventDefault();
    setModalIsOpen1(true);
  };
  const closeModal = () => setModalIsOpen1(false);

  // Invoice ADD
  const handleAddField = (e) => {
    e.preventDefault();
    const values = [...formValues];
    values.push({
      transactionValue: transactionValueRef.current.value,
      qty: qtyRef.current.value,
      unitPrice: unitPriceRef.current.value,
      totalPrice: totalPriceRef.current.value,
    });
    setFormValues(values);
    setModalIsOpen1(false);
  };

  // Invoice DELETE
  const handleDltIn = (e, i) => {
    // e.preventDefault();
    setInvoiceCreateList(
      invoiceCreateList.filter(function (item, index) {
        return index !== i;
      })
    );
  };

  // INVOICE LIST
  const { invoiceCreateList, setInvoiceCreateList } = useInvoiceCreateListCtx();

  const [selectedRows, setSelectedRows] = useState([]);
  const handleRowSelect = (rowKey) => {
    rowKey["productPiece"] = 1;
    if (selectedRows.includes(rowKey)) {
      setSelectedRows(
        selectedRows.filter((key) => key.productId !== rowKey.productId)
      );
    } else {
      setSelectedRows([...selectedRows, rowKey]);
    }
  };
  console.log(invoiceCreateList);
  return (
    <div className="w-full h-full">
      {invoiceCreateList ? (
        <>
          <form onSubmit={handleSubmit}>
            <p className="uppercase font-bold text-[12px] mb-4">
              Байгууллагын мэдээлэл
            </p>
            <div>
              <label className="text-[14px]">Төлөгч байгууллагын нэр :</label>
              <input
                required
                className="border border-[gray] border-[1px] w-full mt-1 px-3 py-1 rounded-md outline-0"
                name="custName"
              />
            </div>
            <div>
              <label className="text-[14px]">Регистрийн дугаар :</label>
              <input
                required
                className="border border-[gray] border-[1px] w-full mt-1 px-3 py-1 rounded-md outline-0"
                name="custRegister"
              />
            </div>
            <div>
              <label className="text-[14px]">Хаяг :</label>
              <input
                required
                className="border border-[gray] border-[1px] w-full mt-1 px-3 py-1 rounded-md outline-0"
                name="custAddress"
              />
            </div>
            {/* <div>
              <label className="text-[14px]">Нэхэмжилсэн огноо :</label>
              <input
                required
                type="date"
                defaultValue="2023-12-31"
                className="border border-[gray] border-[1px] w-full mt-1 px-3 py-1 rounded-md outline-0"
                name="invoiceDate"
              />
            </div> */}
            <div>
              <label className="text-[14px]">Утас :</label>
              <input
                required
                type="text"
                className="border border-[gray] border-[1px] w-full mt-1 px-3 py-1 rounded-md outline-0"
                name="phone"
              />
            </div>

            <button
              type="submit"
              className="border absolute bottom-0 bg-[#000] text-white mt-3 px-3 py-1 rounded-md outline-0"
            >
              Нэхэмжлэл татах
            </button>
          </form>

          <div className="overflow-scroll mt-5 mb-20 w-[95vw] md:w-full md:overflow-auto rounded-md">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-2 py-2">
                    #
                  </th>
                  <th scope="col" className="px-2 py-2">
                    №
                  </th>
                  <th scope="col" className="px-2 py-2">
                    Гүйлгээний утга
                  </th>
                  <th scope="col" className="px-2 py-2">
                    <div className="flex items-center">Тоо хэмжээ</div>
                  </th>
                  <th scope="col" className="px-2 py-2">
                    <div className="flex items-center">Нэгжийн үнэ</div>
                  </th>
                  <th scope="col" className="px-2 py-2">
                    <div className="flex items-center">Нийт үнэ</div>
                  </th>
                  <th scope="col" className="px-2 py-2">
                    <div className="flex items-center">Устгах</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceCreateList &&
                  invoiceCreateList.map((item, i) => {
                    return (
                      <tr
                        key={item.productId}
                        className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700 ${
                          selectedRows.includes(item)
                            ? "bg-gray-100"
                            : "bg-[#000]"
                        }`}
                      >
                        <td className="px-2 py-2">
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(item)}
                            onChange={() => handleRowSelect(item)}
                          />
                        </td>
                        <td className="px-2 py-2">{i + 1}</td>
                        <td className="px-2 py-2">{item.productName}</td>
                        <td className="px-2 py-2">
                          <Counter
                            id={item.productId}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                          />
                        </td>

                        <td className="px-2 py-2">{item.productPrice}</td>
                        <td className="px-2 py-2">
                          {selectedRows.includes(item)
                            ? item.productPrice * item.productPiece
                            : item.productPrice}
                        </td>
                        <td className="px-2 py-2">
                          <button onClick={() => handleDltIn(item, i)}>
                            <HiXMark />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <video autoPlay={true} playsInline controls className={`w-full mb-10`}>
          <source src={`./info.mp4`} type="video/mp4" />
        </video>
      )}
      <Modal isOpen={modalIsOpen1} closeModal={closeModal}>
        <input
          type="text"
          placeholder="Гүйлгээний утга"
          name="transactionValue"
          ref={transactionValueRef}
          className="border border-[gray] border-[1px] w-full mt-3 text-[14px] px-3 py-1 rounded-md outline-0"
        />
        <input
          type="text"
          placeholder="Тоо хэмжээ"
          name="qty"
          ref={qtyRef}
          className="border border-[gray] border-[1px] w-full mt-3 text-[14px] px-3 py-1  rounded-md outline-0"
        />
        <input
          type="text"
          placeholder="Нэгжийн үнэ"
          name="unitPrice"
          ref={unitPriceRef}
          className="border border-[gray] border-[1px] w-full mt-3 text-[14px] px-3 py-1  rounded-md outline-0"
        />
        <input
          type="text"
          placeholder="Нийт үнэ"
          name="totalPrice"
          ref={totalPriceRef}
          className="border border-[gray] border-[1px] w-full mt-3 text-[14px] px-3 py-1  rounded-md outline-0"
        />

        <button
          onClick={handleAddField}
          //  onClick={openModal}
          className="border w-full bg-[#000] text-white mt-3 px-3 py-1 rounded-md outline-0"
        >
          Нэхэмжлэл нэмэх
        </button>
      </Modal>
    </div>
  );
};

export default Nehemjlel;
