import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const SubMenuFilter = ({ subCtg, price, priceSelect, sub, cat }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [selectedCtg, setSelectedCtg] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const handleCtgChange = (option) => {

    setSelectedCtg(option);
    setIsHovered(!isHovered);
  };
  const handlePriceChange = (event, option) => {
    setSelectedPrice({
      check: event.target.checked,
      id: option,
    });
  };
  useEffect(() => {
    if (
      selectedCtg?.pageCategoryId === 1 ||
      selectedCtg?.pageCategoryId === 2
    ) {
      if (selectedCtg && selectedPrice?.check === true) {
        navigate({
          pathname: "/filter",
          search: `?cat=${selectedCtg.pageCategoryId}&sub=${selectedCtg.id}&price=${selectedPrice?.id}`,
        });
      } else if (selectedCtg) {
        navigate({
          pathname: "/filter",
          search: `?cat=${selectedCtg.pageCategoryId}&sub=${selectedCtg.id}`,
        });
      } else if (selectedPrice) {
        navigate({
          pathname: "/filter",
          search: `?cat=${cat}&price=${selectedPrice?.id}&sub=${sub}`,
        });
      }
    }
    if (selectedCtg?.pageCategoryId === 3) {
      navigate({
        pathname: "/info",
        search: `name=${selectedCtg.name}`,
      });
    }
    if (selectedCtg?.pageCategoryId === 4) {
      navigate({
        pathname: "/invoice",
        search: `name=${selectedCtg.name}`,
      });
    }
  }, [selectedCtg, selectedPrice, priceSelect, sub, cat]);

  return (
    <div className="sticky top-0 w-full">
      <p className="mb-3 font-[700]  font-bold text-[14px]">Ангилал</p>
      {subCtg?.map((e, i) => {
        return (
          <div className="p-[2px]" key={i}>
            <label className="flex items-center">
              <input
                type="checkbox"
                className="rounded"
                checked={
                  selectedCtg
                    ? selectedCtg?.id === e.id
                    : parseInt(sub) === e.id
                }
                onChange={() => handleCtgChange(e)}
              />

      <span
          className={`text-[13px] ml-2 ${
            
            selectedCtg?.id === e.id?
                "animate__animated animate__zoomIn" : ""
          }`}
        >
          {e.name}
        </span>
            </label>
          </div>
        );
      })}
      {subCtg[0]?.pageCategoryId === 1 || subCtg[0]?.pageCategoryId === 2 ? (
        <div>
          <p className="my-3 font-[700]  font-bold text-[14px]">Үнэ</p>
          {price &&
            price?.map((e, i) => {
              return (
                <div className="p-[2px]" key={i}>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="rounded"
                      checked={
                        selectedPrice
                          ? selectedPrice?.check === true &&
                            selectedPrice?.id === e.priceId
                          : ""
                      }
                      onChange={(event) => handlePriceChange(event, e.priceId)}
                    />
                    <span className={`text-[13px] ml-2 ${
            
            selectedPrice?.id === e.priceId?
                "animate__animated animate__zoomIn" : ""
          }`}>
                      {e.priceBegin} - {e.priceEnd}
                    </span>
                  </label>
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SubMenuFilter;
