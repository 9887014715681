import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const SubMenuFilterPhone = ({ subCtg, price, priceSelect, sub, cat }) => {
  const navigate = useNavigate();
  const [selectedCtg, setSelectedCtg] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const handleCtgChange = (option) => {
    setSelectedCtg(option);
  };
  const handlePriceChange = (option) => {
    setSelectedPrice(option);
  };

  function handleFilterPhone(e) {
    e.preventDefault();
    if (
      selectedCtg?.pageCategoryId === 1 ||
      selectedCtg?.pageCategoryId === 2
    ) {
      if (selectedCtg && selectedPrice) {
        navigate({
          pathname: "/filter",
          search: `?cat=${selectedCtg.pageCategoryId}&sub=${selectedCtg.id}&price=${selectedPrice}`,
        });
      } else if (selectedCtg) {
        navigate({
          pathname: "/filter",
          search: `?cat=${selectedCtg.pageCategoryId}&sub=${selectedCtg.id}`,
        });
      } else if (selectedPrice) {
        navigate({
          pathname: "/filter",
          search: `?cat=${cat}&price=${selectedPrice}&sub=${sub}`,
        });
      }
    }
    if (selectedCtg?.pageCategoryId === 3) {
      navigate({
        pathname: "/info",
        search: `name=${selectedCtg.name}`,
      });
    }
    if (selectedCtg?.pageCategoryId === 4) {
      navigate({
        pathname: "/invoice",
        search: `name=${selectedCtg.name}`,
      });
    }
  }

  return (
    <div className="top-0 w-full">
      <form onSubmit={handleFilterPhone}>
        <p className="mb-3 font-[500]">Ангилал</p>
        {subCtg?.map((e, i) => {
          return (
            <div className="p-[2px]" key={i}>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="rounded"
                  checked={
                    selectedCtg
                      ? selectedCtg?.id === e.id
                      : parseInt(sub) === e.id
                  }
                  onChange={() => handleCtgChange(e)}
                />
                <span className=" text-[13px] ml-2"> {e.name}</span>
              </label>
            </div>
          );
        })}
        {subCtg[0]?.pageCategoryId === 1 || subCtg[0]?.pageCategoryId === 2 ? (
          <div>
            <p className="my-3 font-[500]">Үнэ</p>
            {price &&
              price?.map((e, i) => {
                return (
                  <div className="p-[2px]" key={i}>
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="rounded"
                        checked={
                          selectedPrice
                            ? selectedPrice === e.priceId
                            : parseInt(priceSelect) === e.priceId
                        }
                        onChange={() => handlePriceChange(e.priceId)}
                      />
                      <span className="uppercase text-[13px] ml-2">
                        {e.priceBegin} - {e.priceEnd}
                      </span>
                    </label>
                  </div>
                );
              })}
          </div>
        ) : (
          <></>
        )}
        <input
          type="submit"
          value="Шүүх"
          className="w-full bg-[black] my-10 p-1 rounded-md text-white"
        />
      </form>
    </div>
  );
};

export default SubMenuFilterPhone;
