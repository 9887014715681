const Card = ({ news, handleProjectEach }) => {
  return (
    <button
      onClick={() => handleProjectEach(news)}
      className=" p-5 rounded-md"
      style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px" }}
    >
      <img
        src={`${process.env.REACT_APP_UPLOADS_URL}/${news?.newsCoverImg}`}
        alt="image"
      />
      <p className="my-2 text-[15px]">{news.newsTitle}</p>
      <p className="bg-[#f5f7f6] my-2 p-2 text-[#6c757d] text-[14px] rounded-md">
        Огноо : {news.createdAt}
      </p>
    </button>
  );
};

export default Card;
