
const Card = ({ product }) => {
  return (
    <div className="rounded-md mx-3">
      <p className=" text-center">
      
        <span className="uppercase font-bold ">"{product.name}"</span>{" "}
        Борлуулалтын төв
      </p>
      <p className=" my-2 p-2 text-[#6c757d] text-[14px] text-center">
        {product.description}
      </p>
      <iframe
        src={`${product.location}`}
        allowfullscreen="true"
        loading="lazy"
        className="w-full h-[300px] rounded-md"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Card;
