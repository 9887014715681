import SubMenuFilter from "../filter/subMenuFilter";
import { useLocation, useNavigate } from "react-router-dom";
import About from "./About";
import Card from "../card/card-news";

const News = ({ subCtg, isOpen, news }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");

  function handleProjectEach(news) {
    navigate(`/info/${news.newsId}`);
  }
  return (
    <div className="w-full h-full relative flex">
      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter subCtg={subCtg} isOpen={isOpen} />
      </div>
      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        {name === "Мэдээ" || name === "МЭДЭЭ" ? (
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-4">
            {news?.map((news, i) => {
              return (
                <Card
                  key={i}
                  news={news}
                  handleProjectEach={() => handleProjectEach(news)}
                />
              );
            })}
          </div>
        ) : (
          <>
            <About />
          </>
        )}
      </div>
    </div>
  );
};

export default News;
