const getAllBanner = async () => {
  return await fetch(`${process.env.REACT_APP_ADMIN_URL}/banner`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const bannerServices = {
  getAllBanner,
};
