import { useCategory } from "../contexts/categoryCtx";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Main() {
  const { ctgPage, ctgSub } = useCategory();
  const { ctgpage, setCtgPage } = ctgPage;
  return (
    <section className="w-full min-h-screen relative flex overflow-hidden coverImg">
      <Helmet>
        <title>
          Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin
          huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн
          санал, ajliin huwtsas, gaz, nehemjlel
        </title>
        <meta
          name="title"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          name="description"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          name="keywords"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:title"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:description"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta property="og:url" content={`http://hab.mn`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="z-20 absolute left-0 top-0 w-full h-full bg-[#000] bg-opacity-30"></div>
      <div className="uk-container-main flex-grow z-30 md:py-20">
        <div className="flex flex-col h-full w-full items-center justify-center">
          <div className="flex mx-auto mb-5">
            <img
              src="./logo2.png"
              className="m-auto w-[170px] md:w-[200px] opacity-75 animate__animated animate__bounce"
              alt="Сутайн ихэр цэцэг ХХК"
            />
          </div>
          <div className="flex flex-wrap justify-center my-auto ">
            {ctgpage?.map((e, i) => {
              return (
                <div key={i} className="lg:w-1/5 md:w-1/3 w-1/2 p-2 ">
                  <button className="ml-5 w-[140px] h-[140px] md:w-[180px] md:h-[180px] rotate-45 border rounded-md border-white bg-[#000] bg-opacity-50 border-opacity-50 text-center flex flex-col justify-center p-2 md:p-7 animate__animated animate__zoomInDown hover:bg-opacity-60 ">
                    <Link to={`/${e.slug}`} className="-rotate-45">
                      <div>
                        <img
                          src={`./logo/${e.logo}.png`}
                          className="max-w-3/4 mx-auto mb-3 max-h-[50px] md:max-h-[100px]"
                          alt={`${e.name}`}
                        />
                        <span className="text-[white] text-[12px] md:text-[13px] uppercase font-[400] mt-5 -rotate-45">
                          {e.name}
                        </span>
                      </div>
                    </Link>
                  </button>
                </div>
              );
            })}
          </div>

          <div className="mx-auto mt-auto">
            <span className="text-white text-xs animate__animated animate__backInDown">
              Copyright © 2000-2024 Сутайн ихэр цэцэг ХХК
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Main;
