import React from "react";
import { useState } from "react";

export default function invoiceCreate({ isOpen, closeModal, children }) {
  return (
    <>
      {isOpen && (
        <div className="fixed  inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-50"
            onClick={closeModal}
          ></div>
          <div className="bg-white rounded-lg relative mx-2 p-8 max-w-md mx-auto z-10">
            <button className="absolute top-0 right-0 p-4" onClick={closeModal}>
              &times;
            </button>
            {children}
          </div>
        </div>
      )}
    </>
  );
}
