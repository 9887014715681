import React, { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./userCtx";
import { invoiceServices } from "../services/invoiceServices";

const initialVal = {
  invoiceList: null,
  setInvoiceList: () => {},
};

export const InvoiceListContext = createContext(initialVal);

export function useInvoiceList() {
  return useContext(InvoiceListContext);
}
export const InvoiceListProvider = (props) => {
  const [invoiceList, setInvoiceList] = useState();
  const { user, setUser } = useUser();
  useEffect(() => {
    if (user) {
      invoiceServices
        .getByIdInvoice(user.id)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setInvoiceList(result.data);
          }
        });
    }
  }, [user]);

  return (
    <InvoiceListContext.Provider value={{ invoiceList, setInvoiceList }}>
      {props.children}
    </InvoiceListContext.Provider>
  );
};
export default InvoiceListProvider;
