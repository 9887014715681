import Card from "../card/card";
import SubMenuFilter from "../filter/subMenuFilter";
import filterDrawer from "../filter/filterDrawer";
import Pagination from "../pagination/pagination";
import { useNavigate } from "react-router-dom";
import { productServices } from "../../services/productServices";
import { useState } from "react";
import { Helmet } from "react-helmet";

const Product = ({ subCtg, isOpen, price, data, setProduct }) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  function handleProjectEach(product) {
    navigate(`/product/${product.productId}/${product.productSubCtgType}`);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    productServices
      .getAllProduct(newPage, 1)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setProduct({
            totalPages: result?.totalPages,
            data: result?.data,
          });
        }
      })
      .finally(() => {});
  };

  return (
    <div className="w-full h-full relative flex">
      <Helmet>
        <title>
          Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin
          huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн
          санал, ajliin huwtsas, gaz, nehemjlel
        </title>
        <meta
          name="title"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          name="description"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          name="keywords"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:keywords"
          content="Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:title"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:description"
          content=" Ажлын хувцас, ажлын хувцас, ажлын гутал, хөдөлмөр хамгаалал, Ajliin huvtsas, Хөдөлмөр хамгааллын хувцас, газ, мэдээ, нэхэмжлэл, үнийн санал, ajliin huwtsas, gaz, nehemjlel"
          data-rh="true"
        />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_META}/ajliin-huwtsas`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter subCtg={subCtg} isOpen={isOpen} price={price} />
      </div>
      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 z-20">
          {data.data &&
            data.data?.map((product, i) => {
              return (
                <Card
                  key={i}
                  product={product}
                  handleProjectEach={() => handleProjectEach(product)}
                />
              );
            })}
        </div>
        <div>
          <Pagination
            currentPage={currentPage}
            totalPages={data?.totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Product;
