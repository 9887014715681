import React, { useState, useEffect } from "react";

export default function Counter({ id, selectedRows, setSelectedRows }) {
  const [count, setCount] = useState(1);

  function handleCountMinus(id, number) {
    setCount(number - 1);
    setSelectedRows(
      selectedRows?.map((e) => {
        if (e.productId === id) {
          if (number !== 1) {
            e.productPiece = number - 1;
            return e;
          }
        }
        return e;
      })
    );
  }
  function handleCountAdd(id, number) {
    setCount(number + 1);
    setSelectedRows(
      selectedRows?.map((e) => {
        if (e.productId === id) {
          e["productPiece"] = number + 1;
          return e;
        }
        return e;
      })
    );
  }

  return (
    <div className="flex w-full">
      <button
        className="border hover:border-[red] px-3 mr-3 rounded-md"
        onClick={() => {
          handleCountMinus(id, count);
        }}
      >
        -
      </button>
      <p className="font-[600]">{count}</p>
      <button
        className="border hover:border-[red] px-3 ml-3 rounded-md"
        onClick={() => {
          handleCountAdd(id, count);
        }}
      >
        +
      </button>
    </div>
  );
}
