"use client";
import React, { useEffect, useState } from "react";
import { userServices } from "../../services/userServices";
import { useUser } from "../../contexts/userCtx";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { Icon } from "react-icons-kit";

export default function User() {
  const { user, setUser } = useUser();

  // Password icon
  const [password, setPassword] = useState("");
  const [typeSign, setTypeSign] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (typeSign === "password") {
      setIcon(eye);
      setTypeSign("text");
    } else {
      setIcon(eyeOff);
      setTypeSign("password");
    }
  };

  function getFormSubmit(e) {
    e.preventDefault();
    const { lastName, firstName, email, password, phone, dob } = e.target;
    userServices
      .getUserEdit(
        user?.id,
        lastName.value,
        firstName.value,
        email.value,
        password.value,
        phone.value,
        dob.value
      )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .finally(() => {});
  }
  return (
    <div>
      <form onSubmit={getFormSubmit}>
        <h2 className="flex justify-center pb-4 text-h5 font-bold uppercase sm:text-h2">
          Хэрэглэгчийн мэдээлэл
        </h2>
        <input
          required
          type="text"
          name="lastName"
          defaultValue={user?.lastName}
          placeholder="Овог"
          className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
        />
        <input
          required
          type="text"
          name="firstName"
          defaultValue={user?.firstName}
          placeholder="Нэр"
          className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
        />
        <input
          required
          type="email"
          name="email"
          defaultValue={user?.email}
          placeholder="Имэйл хаяг"
          className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
        />

        <input
          required
          type="text"
          name="phone"
          defaultValue={user?.phone}
          placeholder="Утасны дугаар"
          className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
        />
        <input
          required
          type="date"
          name="dob"
          defaultValue={user?.dob}
          placeholder="Төрсөн он сар өдөр"
          className="w-full rounded-full border border-transparent bg-[#f5f7f6] mb-2.5 py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
        />
        {/* <div class="flex justify-between items-center mb-2.5 ">
          <input
            type={typeSign}
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Шинэ нууц үг"
            className="w-full  rounded-full border border-transparent bg-[#f5f7f6] py-3 px-3 text-base outline-none transition duration-200 focus:border-primary-500 sm:text-xs"
          />
          <span class="flex justify-around items-center" onClick={handleToggle}>
            <Icon class="absolute mr-10" icon={icon} />
          </span>
        </div> */}
        <button
          type="submit"
          className="w-full uppercase rounded-full border border-transparent bg-[#000] text-[#fff] py-3 px-3  outline-none transition duration-200 focus:border-primary-500 text-base sm:text-xs"
        >
          Засах
        </button>
      </form>
    </div>
  );
}
