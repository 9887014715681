import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CategoryProvider } from "./contexts/categoryCtx";
import { PriceProvider } from "./contexts/priceCtx";
import { ProductProvider } from "./contexts/productCtx";
import { ProductGasProvider } from "./contexts/gazCtx";
import { NewsProvider } from "./contexts/NewsCtx";
import { InvoiceProvider } from "./contexts/invoiceCtx";
import { AboutProvider } from "./contexts/aboutCtx";
import { BannerProvider } from "./contexts/bannerCtx";
import { TokenProvider } from "./contexts/tokenCtx";
import { UserProvider } from "./contexts/userCtx";
import { InvoiceListProvider } from "./contexts/invoiceListCtx";
import { ModalProvider } from "./contexts/modalCtx";
import { InvoiceLocalProvider } from "./contexts/invoiceLocal";
import { UniinSanalLocalProvider } from "./contexts/uniinSanalLocal";
import { UniinSanalCtxProvider } from "./contexts/uniinSanalCtx";
import { InvoiceCreateListCtxProvider } from "./contexts/InvoiceCreateList";
import { BrowserRouter } from "react-router-dom";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ModalProvider>
        <CategoryProvider>
          <PriceProvider>
            <ProductProvider>
              <ProductGasProvider>
                <NewsProvider>
                  <InvoiceProvider>
                    <AboutProvider>
                      <BannerProvider>
                        <TokenProvider>
                          <UserProvider>
                            <InvoiceListProvider>
                              <InvoiceLocalProvider>
                                <UniinSanalLocalProvider>
                                  <UniinSanalCtxProvider>
                                    <InvoiceCreateListCtxProvider>
                                      <App />
                                    </InvoiceCreateListCtxProvider>
                                  </UniinSanalCtxProvider>
                                </UniinSanalLocalProvider>
                              </InvoiceLocalProvider>
                            </InvoiceListProvider>
                          </UserProvider>
                        </TokenProvider>
                      </BannerProvider>
                    </AboutProvider>
                  </InvoiceProvider>
                </NewsProvider>
              </ProductGasProvider>
            </ProductProvider>
          </PriceProvider>
        </CategoryProvider>
      </ModalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
