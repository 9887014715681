import {
  Product,
  News,
  NewsDetails,
  Gas,
  UniinSanal,
  KhBarih,
  ProductDetails,
  ProductFilter,
  ProductSearch,
  User,
  InvoiceList,
  ResetPassToken,
  Feedback,
} from "./components";
import { Route, Routes, useLocation } from "react-router-dom";
import { useCategory } from "./contexts/categoryCtx";
import { usePrice } from "./contexts/priceCtx";
import { useProduct } from "./contexts/productCtx";
import { useProductGas } from "./contexts/gazCtx";
import { useInvoice } from "./contexts/invoiceCtx";
import { useNews } from "./contexts/NewsCtx";
import { useState } from "react";
import { useToken } from "./contexts/tokenCtx";
import Layout from "./content/Layout";
import Page from "./content/page";
import "./App.css";
import "animate.css";

function App() {
  const location = useLocation();
  const { product, setProduct } = useProduct();
  const { gas, setGas } = useProductGas();
  const { invoice, setInvoice } = useInvoice();
  const { ctgPage, ctgSub } = useCategory();
  const { ctgpage, setCtgPage } = ctgPage;
  const { ctgsub, setCtgSub } = ctgSub;
  const { price, setPrice } = usePrice();
  const { news, setNews } = useNews();
  const { token, setToken } = useToken();
  const [isOpen, setIsOpen] = useState(true);

  const subPage = ctgpage?.filter((e) => {
    if (e.slug === location.pathname.slice(1, 50)) {
      return e;
    }
  });

  const subCtg = ctgsub?.filter((e) => {
    if (e.pageCategoryId === subPage[0]?.id) {
      return e;
    }
  });

  return (
    <div>
      <Feedback />
      <Routes>
        <Route path="/" element={<Page />} />
        <Route
          path="/"
          element={
            <Layout
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              subCtg={subCtg}
              subPage={subPage}
              ctgsub={ctgsub}
              ctgpage={ctgpage}
              product={invoice}
              price={price}
            />
          }
        >
          <Route
            path="ajliin-huwtsas"
            element={
              <Product
                subCtg={subCtg}
                isOpen={isOpen}
                price={price}
                data={product}
                setProduct={setProduct}
              />
            }
          />
          <Route
            path="gaz-dugui"
            element={
              <Gas
                subCtg={subCtg}
                isOpen={isOpen}
                price={price}
                gas={gas}
                setGas={setGas}
              />
            }
          />
          <Route
            path="info"
            element={<News subCtg={subCtg} isOpen={isOpen} news={news} />}
          />
          <Route
            path="invoice"
            element={
              <UniinSanal
                subCtg={subCtg}
                isOpen={isOpen}
                product={invoice}
                setInvoice={setInvoice}
              />
            }
          />
          <Route
            path="kholboo-barih"
            element={<KhBarih subCtg={subCtg} isOpen={isOpen} />}
          />
          <Route path="product/:id/:sub" element={<ProductDetails />} />
          <Route
            path="result"
            element={
              <ProductSearch ctgsub={ctgsub} isOpen={isOpen} price={price} />
            }
          />
          <Route path="info/:id" element={<NewsDetails />} />
          <Route
            path="filter"
            element={
              <ProductFilter ctgsub={ctgsub} isOpen={isOpen} price={price} />
            }
          />
          <Route path="user/:id" element={<User />} />
          <Route path="list/:id" element={<InvoiceList />} />
          <Route path="pass/:token" element={<ResetPassToken />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
