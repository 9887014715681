import React, { createContext, useContext, useEffect, useState } from "react";

const initialVal = {
  invoiceLocal: null,
  setInvoiceLocal: () => {},
};

export const InvoiceLocalContext = createContext(initialVal);

export function useInvoiceLocal() {
  return useContext(InvoiceLocalContext);
}

export const InvoiceLocalProvider = (props) => {
  const [invoiceLocal, setInvoiceLocal] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("invoice")) {
      setInvoiceLocal(JSON.parse(localStorage.getItem("invoice")));
    }
  }, []);

  useEffect(() => {
    if (invoiceLocal) {
      localStorage.setItem("invoice", JSON.stringify(invoiceLocal));
    } else {
      localStorage.removeItem("invoice");
    }
  }, [invoiceLocal]);

  return (
    <InvoiceLocalContext.Provider value={{ invoiceLocal, setInvoiceLocal }}>
      {props.children}
    </InvoiceLocalContext.Provider>
  );
};
export default InvoiceLocalProvider;
