import SubMenuFilter from "../filter/subMenuFilter";
import Card from "../card/card-kholboo";

const data = [
  {
    id: 2,
    name: "Төв салбар",
    location:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.2533179890543!2d106.94479647685165!3d47.91213576682851!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9693be9c56f7b5%3A0x901863583b7999a4!2z0KLSr9C80Y3QvSDQvdCw0YHRgiDRhdC-0YLRhdC-0L0!5e0!3m2!1sen!2smn!4v1692957944707!5m2!1sen!2smn",
    description:
      'ХӨСҮТ-ийн чанх урд BSB-тэй байрны зүүн талд "Түмэн наст" хотхоны 36Б байр Ажлын хувцасны дэлгүүр',
  },
  {
    id: 1,
    name: "Салбар 2",

    location:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2674.158393650386!2d106.86226489241953!3d47.91397259250174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d9692e040d2c2db%3A0xd9f1d83cfdd61198!2zMTAt0YAg0YXQvtGA0L7QvtC70L7Quw!5e0!3m2!1sen!2smn!4v1692958757962!5m2!1sen!2smn",
    description:
      "10-р хороолол MT колонктой уулзварын баруун урд зам дагуу Ажлын хувцасны дэлгүүр",
  },
];
const KhBarih = ({ subCtg, isOpen }) => {
  return (
    <div className="w-full h-full relative flex">
      <div
        className={
          isOpen !== true
            ? "hidden  md:w-[25vw] lg:w-[20vw]" /******* FILTER CLOSE *******/
            : "hidden md:block md:w-[25vw] lg:w-[20vw]" /******* FILTER OPEN *******/
        }
      >
        <SubMenuFilter subCtg={subCtg} isOpen={isOpen} />
      </div>
      <div
        className={
          isOpen !== true
            ? "md:w-[100vw] lg:w-[100vw]"
            : "md:w-[75vw] lg:w-[80vw]"
        }
      >
        <div>
          <div className="w-full flex">
            {data?.map((product, i) => {
              return <Card key={i} product={product} />;
            })}
          </div>
          {/* <div>
            <p className="text-lg font-medium text-center">
              Санал хүсэлт илгээх
            </p>
            <input type="text" className="border w-full" />
            <input type="text" className="border w-full mt-5" />
            <button
              type="submit"
              className="bg-[blue] w-full mt-5 p-1 rounded-md border text-white"
            >
              илгээх
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default KhBarih;
