import React, { createContext, useContext, useEffect, useState } from "react";
import { bannerServices } from "../services/bannerServices";

const initialVal = {
  banner: null,
  setBanner: () => {},
};

export const BannerContext = createContext(initialVal);

export function useBanner() {
  return useContext(BannerContext);
}

export const BannerProvider = (props) => {
  const [banner, setBanner] = useState();
  useEffect(() => {
    bannerServices
      .getAllBanner()
      .then((response) => response.json())
      .then((result) => {
        if (result?.success === true) {
          setBanner(result?.data);
        }
      })
      .finally(() => {});
  }, []);

  return (
    <BannerContext.Provider value={{ banner, setBanner }}>
      {props.children}
    </BannerContext.Provider>
  );
};
export default BannerProvider;
