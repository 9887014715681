import React, { createContext, useState, useContext, useEffect } from "react";
import { categoryServices } from "../services/categoryServices";

const initialVal = {
  category: [],
  setCategory: () => {},
};

export const CategoryContext = createContext(initialVal);

export function useCategory() {
  return useContext(CategoryContext);
}

export const CategoryProvider = (props) => {
  const [ctgpage, setCtgPage] = useState([]);
  const [ctgsub, setCtgSub] = useState([]);
  useEffect(() => {
    categoryServices
      .getAllCategoryPage()
      .then((response) => response.json())
      .then((result) => {
        setCtgPage(result.data);
      });
    categoryServices
      .getAllCategorySub()
      .then((response) => response.json())
      .then((result) => {
        setCtgSub(result.data);
      });
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        ctgPage: { ctgpage, setCtgPage },
        ctgSub: { ctgsub, setCtgSub },
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};
