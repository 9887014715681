const getAllCategoryPage = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category?type=page`,
    requestOptions
  );
};
const getAllCategorySub = async () => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/category?type=sub`,
    requestOptions
  );
};

export const categoryServices = {
  getAllCategoryPage,
  getAllCategorySub,
};
