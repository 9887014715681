import { useState, useEffect } from "react";
import { useInvoiceLocal } from "../../contexts/invoiceLocal";
import { useUniinSanalLocal } from "../../contexts/uniinSanalLocal";

const Card = ({ product, handleProjectEach }) => {
  const [hovered, setHovered] = useState({
    text: "",
    state: false,
  });
  const { invoiceLocal, setInvoiceLocal } = useInvoiceLocal();
  const { uniinSanalLocal, setUniinSanalLocal } = useUniinSanalLocal();
  function handleBtnInvoice(product) {
    if (!invoiceLocal.includes(product.productId)) {
      setInvoiceLocal([...invoiceLocal, product.productId]);
    }
    localStorage.setItem("invoice", JSON.stringify(invoiceLocal));
  }

  function handleBtnPriceQuote(product) {
    if (!uniinSanalLocal.includes(product.productId)) {
      setUniinSanalLocal([...uniinSanalLocal, product.productId]);
    }
    localStorage.setItem("pitch", JSON.stringify(uniinSanalLocal));
  }

  return (
    <div
      className="p-3 rounded-md relative flex flex-col justify-end  text-left z-30 bg-[#fff]"
      style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px" }}
    >
      <button onClick={() => handleProjectEach(product)} className="w-full">
        <div className="absolute top-[4px] text-[14px] text-[#fff] left-2">
          {product?.productType === "шинэ" ? (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          ) : product?.productType === "хуучин" ? (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          ) : product?.productType === "бэлэн" ? (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          ) : product?.productType === "захиалгат" ? (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          ) : product?.productType === "шинэ/захиалгат" ? (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          ) : product?.productType === "хуучин/захиалгат" ? (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          ) : (
            <span className="bg-gray-300   uppercase text-[11px] p-1  rounded-tl-md rounded-br-lg border border-lg text-[#6c757d]">
              {product?.productType}
            </span>
          )}
        </div>

        <img
          src={`${process.env.REACT_APP_UPLOADS_URL}/${product?.productCoverImg}`}
          alt="image"
          className="rounded-lg"
        />

        <p className="my-2 text-[14px] text-left font-[600] ">
          {product.productName}
        </p>
        <p className="bg-gray-300   my-2 p-2 text-[#6c757d] text-[14px]  text-left  rounded-tl-md rounded-br-lg border border-lg">
          код : {product.productCode}
        </p>
        {/* <p className="p-0 font-[500]">{product.productPrice} ₮</p> */}
      </button>
      <div className="flex items-center justify-between">
        <p className="p-0 font-[600]">
          {product.productPrice.toLocaleString("en-US", { style: "decimal" })}₮
        </p>

        <div className="flex">
          <button
            className={` ${
              invoiceLocal?.includes(product.productId)
                ? "bg-[black]"
                : "bg-gray-300 "
            }  ml-1 p-1 text-[gray] text-[14px] rounded-full relative `}
            onClick={() => handleBtnInvoice(product)}
            onMouseEnter={() =>
              setHovered({
                text: "нэхэмжлэл нэмэх",
                state: true,
              })
            }
            onMouseLeave={() =>
              setHovered({
                text: "нэхэмжлэл нэмэх",
                state: false,
              })
            }
          >
            <p className="w-[20px] btn text-[12px]">Н</p>
          </button>
          <button
            className={` ${
              uniinSanalLocal?.includes(product.productId)
                ? "bg-[black]"
                : "bg-gray-300 "
            }  ml-1 p-1 text-[gray] text-[14px] rounded-full relative `}
            onClick={() => handleBtnPriceQuote(product)}
            onMouseEnter={() =>
              setHovered({
                text: "үнийн санал нэмэх",
                state: true,
              })
            }
            onMouseLeave={() =>
              setHovered({
                text: "үнийн санал нэмэх",
                state: false,
              })
            }
          >
            <p className="w-[20px] btn1 text-[12px]">Ү</p>
          </button>
        </div>
        {hovered?.state && (
          <div className="absolute bottom-[-2px] right-3 text-[12px]">
            <p>{hovered?.text}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Card;
