import React, { createContext, useContext, useEffect, useState } from "react";

const initialVal = {
  uniinSanalLocal: null,
  setUniinSanalLocal: () => {},
};

export const UniinSanalLocalContext = createContext(initialVal);

export function useUniinSanalLocal() {
  return useContext(UniinSanalLocalContext);
}

export const UniinSanalLocalProvider = (props) => {
  const [uniinSanalLocal, setUniinSanalLocal] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("pitch")) {
      setUniinSanalLocal(JSON.parse(localStorage.getItem("pitch")));
    }
  }, []);

  useEffect(() => {
    if (uniinSanalLocal) {
      localStorage.setItem("pitch", JSON.stringify(uniinSanalLocal));
    }
    // else {
    //   localStorage.removeItem("pitch");
    // }
  }, [uniinSanalLocal]);

  return (
    <UniinSanalLocalContext.Provider
      value={{ uniinSanalLocal, setUniinSanalLocal }}
    >
      {props.children}
    </UniinSanalLocalContext.Provider>
  );
};
export default UniinSanalLocalProvider;
