import React, { createContext, useContext, useEffect, useState } from "react";
import { uniinSanalServices } from "../services/uniinSanalServices";
import { useUniinSanalLocal } from "./uniinSanalLocal";
import { Notification } from "../utils/Notification";

const initialVal = {
  uniinSanal: null,
  setUniinSanal: () => {},
};

export const UniinSanalContext = createContext(initialVal);

export function useUniinSanalCtx() {
  return useContext(UniinSanalContext);
}

export const UniinSanalCtxProvider = (props) => {
  const [uniinSanal, setUniinSanal] = useState();
  const { uniinSanalLocal, setUniinSanalLocal } = useUniinSanalLocal();

  useEffect(() => {
    if (uniinSanalLocal.length > 0) {
      uniinSanalServices
        .getAllUniinSanal(uniinSanalLocal)
        .then((response) => response.json())
        .then((result) => {
          if (result?.success === true && result?.data.length > 0) {
            setUniinSanal(result?.data);
            Notification.openNotification("success", result.msg);
          }
        })
        .finally(() => {});
    }
  }, [uniinSanalLocal]);
  return (
    <UniinSanalContext.Provider value={{ uniinSanal, setUniinSanal }}>
      {props.children}
    </UniinSanalContext.Provider>
  );
};
export default UniinSanalCtxProvider;
