import React, { createContext, useContext, useEffect, useState } from "react";
import { useToken } from "./tokenCtx";
import { userServices } from "../services/userServices";

const initialVal = {
  user: null,
  setUser: () => {},
};

export const userContext = createContext(initialVal);

export function useUser() {
  return useContext(userContext);
}
export const UserProvider = (props) => {
  const [user, setUser] = useState();
  const { token, setToken } = useToken();
  useEffect(() => {
    if (token) {
      userServices
        .getUserAuth(token)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setUser(result.user);
          }
        })
        .finally(() => {});
    }
  }, [token]);

  return (
    <userContext.Provider value={{ user, setUser }}>
      {props.children}
    </userContext.Provider>
  );
};
export default UserProvider;
