import { useAbout } from "../../contexts/aboutCtx";
import parse from "html-react-parser";
import React, { useState } from "react";

const About = () => {
  const { about, setAbout } = useAbout();
  const [data, setData] = useState("mendchilgee");
  function handleBtnContent(text) {
    setData(text);
  }
  return (
    <div className="w-full h-full text-[14px] text-justify mb-10">
      {/* {about?.map((e, i) => {
        return (
          <div key={i}>
            <p className="text-[20px] mb-5">{parse(e.aboutTitle)}</p>
            <div>{parse(e.aboutBody)}</div>
          </div>
        );
      })} */}
      <div className="flex flex-col md:flex-row mb-10 w-full">
        <button
          onClick={() => handleBtnContent("mendchilgee")}
          className={`${
            data === "mendchilgee"
              ? `bg-[#000] text-[#fff]`
              : `bg-gray-300 text-[#000]`
          } px-6 py-[4px] w-full rounded-t-[20px] rounded-b-[20px] md:rounded-b-[0px] mt-1 md:mt-0`}
        >
          Мэндчилгээ
        </button>
        <button
          onClick={() => handleBtnContent("alsinharaa")}
          className={`${
            data === "alsinharaa"
              ? `bg-[#000] text-[#fff]`
              : `bg-gray-300 text-[#000]`
          } px-6 py-[4px] w-full rounded-t-[20px] rounded-b-[20px] md:rounded-b-[0px] mt-1 md:mt-0`}
        >
          Алсын хараа
        </button>
        <button
          onClick={() => handleBtnContent("zorilgo")}
          className={`${
            data === "zorilgo"
              ? `bg-[#000] text-[#fff]`
              : `bg-gray-300 text-[#000]`
          } px-6 py-[4px] w-full rounded-t-[20px] rounded-b-[20px] md:rounded-b-[0px] mt-1 md:mt-0`}
        >
          Эрхэм зорилго
        </button>
        {/* <button
          onClick={() => handleBtnContent("uria")}
          className={`${
            data === "uria"
              ? `bg-[#000] text-[#fff]`
              : `bg-gray-300 text-[#000]`
          } px-6 py-[4px] w-full rounded-t-[20px] rounded-b-[20px] md:rounded-b-[0px] mt-1 md:mt-0 lg:mr-20`}
        >
          Уриа үг
        </button> */}
      </div>
      {data === "mendchilgee" ? (
        <div className="flex flex-col lg:flex-row">
          <img src="./image.jpg" className="w-[100%] md:w-[35%]" />
          <div className="lg:mx-20  flex flex-col justify-between">
            <p>
              Таны энэ өдрийн амар амгаланг айлтгая. Манай компанийг сонгон
              үйлчлүүлж байгаа эрхэм хүндэт харилцагч байгууллагууд болон түмэн
              олон та бүгдийн минь үйлс нь өөдөө, сэтгэл тэнүүн байх болтугай.
              Бид бүхний хамтын ажиллагаа, урт хугацааны түншлэлүүд, хоёр талдаа
              харилцан олзны богцоон дүүрэн байлгаг хэмээн сайхан монгол
              уламжлалаар найр тавьж, итгэл найдварыг алдахгүйн төлөө хичээн
              ажиллах болно.
            </p>
            <p className="mt-2">
              Манай байгууллага нь 1993 онд байгуулагдаж Импортын ажлын гутал
              борлуулалтаас гараагаа эхэлсэн бөгөөд өнөөг хүртэл бүх төрлийн
              хөдөлмөрхамгаалалын гутал хувцас хэрэгсэл, цэрэг армийн гутал
              хувцас хэрэгсэлийг үйлдвэрийн болон импортоор S3 стандартад
              нийцүүлэн салбар бүрт хэрэглэгдэх чанартай бэлэн хувцас, гутал,
              туслах хэрэгсэлийг 4 улиралд нийцүүлэн чанартай, өргөнөөр
              сонголттойгоор харилцагчийн хүсэлт, шаардлага, үйлажиллагааны
              онцлогт нийцүүлэн өнгө загвар материалын өргөн сонголттойгоор
              үйлдвэрлэх мэргэжлийн, олон жилийн туршлагатай оёдлын
              үйлдвэрлэлийн баг хамт олон хичээнгүйлэн ажиллан худалдан нийлүүлж
              бүтээгдэхүүн дээр хийгдэх байгууллага болон хувь хүний Лого
              хатгамал, бичилт зэргийг хийж эцсийн бүтээгдэхүүнийг харилцагчийн
              гарт тогтмол амжилттай хүргүүлэн харилцагч нарын итгэлийг алдалгүй
              хүрээгээ улам бүр тэлсээр байна.
            </p>
            <p className="mt-2">
              Эдгээрийг 100% өөрийн хөрөнгө оруулалтаар бараа материалын эргэлт
              хийж, улсын татварын санд хувь нэмэрээ оруулсаар ирсэн бөгөөд
              стандарт шаардлагын дагуу энэхүү салбартаа та бүхнийхээ итгэлийг
              дааж, он удаан хамтран ажиллана гэдэгт итгэлтэй байна. Мөн манайд
              шинээр үйлчилгээний төрөл нэмж байгаа бараа бүтээгдэхүүн нь
              Солонгос улсаас оруулж ирж буй хийн тоног төхөөрөмж болон хүнсний
              тоног төхөөрөмжүүд байгаатай та бүхэн танилцана уу.{" "}
            </p>
            <p className="mt-2">
              Тав тухтай орчинд үнийн уян хатан нөхцөлтэйгөөр таныг угтан
              үйлчилье. Тавтай морилно уу.{" "}
            </p>
            <p className="text-right">
              <b>Ж. ОТГОНБААТАР </b> <br /> "Сутайн Ихэр Цэцэг" ХХК-ийн Ерөнхий
              Захирал.
            </p>
          </div>
        </div>
      ) : data === "alsinharaa" ? (
        `Дотоодын ажлын хувцасны үйлдвэрлэлийн чанарыг дээшлүүлж хүн бүрт хүртээмжтэй, сэтгэл ханамжинд
        нийцсэн цаашлаад компани, байгууллагуудын нүүр царай болсон хувцас загварыг дотооддоо
        үйлдвэрлэн таны гарт хүргэх нь бидний зорилго болоод алсын хараа юм`
      ) : data === "zorilgo" ? (
        "Хөлс дусалсан - Хөдөлмөр бүрт - Дэмжигч нь байя."
      ) : (
        "Бүрэн хувцас ажлын бүтээмж"
      )}
    </div>
  );
};

export default About;
