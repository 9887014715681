import React, { useState } from "react";
import { Button, Drawer, Switch } from "antd";
import { feedbackServices } from "../../services/feedbackServices";
import { Notification } from "../../utils/Notification";
import { CiFacebook } from "react-icons/ci";

export default function Feedback() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  function handleOnSubmit(e) {
    e.preventDefault();

    feedbackServices
      .getCreateFeedback(e.target.content.value, e.target.suggestion.value)
      .then((response) => response.json())
      .then((result) => {
        //  console.log(result)
        if (result.success === true) {
          Notification.openNotification("success", result.msg);
          setOpen(false);
        }
      })
      .finally(() => {});
  }
  return (
    <div>
      <Button
        onClick={showDrawer}
        className={`fixed top-[40%] right-[2px] w-[20px] h-[150px] bg-gray-300 rounded-lg`}
      >
        <p className="transform -rotate-90 text-[#000] text-center flex items-center justify-center font-medium  ">
          Санал хүсэлт
        </p>
      </Button>
      <Button
        className={`fixed top-[56%] right-[2px] w-[20px] h-[50px] bg-gray-300 rounded-lg`}
      >
        <p className="transform -rotate-0 text-[#000] text-center flex items-center justify-center font-medium  ">
          <a target="_blank" href="https://www.facebook.com/www.hab.mn">
            <CiFacebook size={23} />
          </a>
        </p>
      </Button>

      <Drawer
        // mask={false}
        width={400}
        title="Бидэнд саналаа ирүүлээрэй"
        onClose={onClose}
        open={open}
      >
        <div>
          <form onSubmit={handleOnSubmit}>
            <div>
              <label>Утга :</label>
              <input
                name="content"
                type="text"
                className="border border-1 border-[gray] w-full p-1 rounded mt-1"
              />
            </div>
            <div className="mt-5">
              <label>Санал :</label>
              <textarea
                name="suggestion"
                className="border border-1 border-[gray] w-full h-[150px] p-1 rounded mt-1"
              ></textarea>
            </div>
            <button
              className="bg-[#000] mt-5 text-[#fff] w-full p-1 rounded"
              type="submit"
            >
              илгээх
            </button>
            {/* <p className="font-medium text-[15px] my-5">Та хариу авах уу?</p>
            <div className="flex mt-1">
              <div>
                <input
                  type="checkbox"
                  name="vehicle1"
                  value="yes"
                  onChange={handleBtnCheck}
                />
                <label for="vehicle1" className="ml-1">
                  Тийм
                </label>
              </div>
              <div className="ml-5">
                <input type="checkbox" name="vehicle1" value="no" />
                <label for="vehicle1" className="ml-1">
                  Үгүй
                </label>
              </div>
            </div> */}
          </form>
        </div>
      </Drawer>
    </div>
  );
}
