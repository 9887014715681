const getAllUniinSanal = async (pitch) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    pitch: pitch,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    `${process.env.REACT_APP_ADMIN_URL}/pitch`,
    requestOptions
  );
};

export const uniinSanalServices = {
  getAllUniinSanal,
};
